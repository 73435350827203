import {Route, Routes} from "react-router";
import SignInSide from "../views/auth/Login";
import {Navigate} from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import Dashboard from "../views/dashboard";
import Warehouse from "../views/warehouse";
import {useSelector} from "react-redux";
import Import from "../views/import";
import AWB from "../views/awb";
import Singing from "../views/signfor";
import Label from "../views/label";
import Pack from "../views/pack";
import Report from "../views/report";
import User from "../views/user";
import NotFoundView from "../views/errors/NotFoundView";
import PackNumber from "../views/report/PackNumber";
import BGCode from "../views/report/BGCode";
import Error from "../views/error"
import {pageAuth} from "../config/userRoleMapping";

const RoutesIndex = () => {

    const userRole = Number(localStorage.getItem('user_role'))
    const userName = useSelector(state => state.user.name)
    const role = useSelector( state => state.user.userRole)


    return <Routes>
        <Route index element={<SignInSide />}  />
        <Route path="/" element={<Navigate to="/signin" replace />}  />
        <Route path="/signin" element={<SignInSide />}  />
        <Route
            path="/dashboard"
            element={
                <ProtectedRoute
                    isRole={true}
                >
                    <Dashboard />
                </ProtectedRoute>
            }
        />
        <Route
            path="/warehouse"
            element={
                <ProtectedRoute
                    isRole={userRole !==3 && role !== 3}
                >
                    <Warehouse />
                </ProtectedRoute>
            }
        />

        <Route
            path="/import"
            element={
                <ProtectedRoute
                    isRole={userRole!==2 && role !== 2}
                >
                    <Import/>
                </ProtectedRoute>
            }
        />
        <Route
            path="/awb"
            element={
                <ProtectedRoute
                    isRole={pageAuth.AWB.includes(userRole) || pageAuth.AWB.includes(role)}
                >
                    <AWB />
                </ProtectedRoute>
            }
        />
        <Route
            path="/signing"
            element={
                <ProtectedRoute
                    isRole={userRole !==3 && role !== 3}
                >
                    <Singing />
                </ProtectedRoute>
            }
        />
        <Route
            path="/label"
            element={
                <ProtectedRoute
                    isRole={userRole !==3 && role !== 3}
                >
                    <Label />
                </ProtectedRoute>
            }
        />
        <Route
            path="/pack"
            element={
                <ProtectedRoute
                    isRole={userRole !==3 && role !== 3}
                >
                    <Pack/>
                </ProtectedRoute>
            }
        />
        <Route
            path="/report"
            element={
                <ProtectedRoute
                    isRole={pageAuth.REPORT.includes(userRole) || pageAuth.REPORT.includes(role)}
                >
                    <Report />
                </ProtectedRoute>
            }
        >
            <Route path="packnumber"
                   element={<PackNumber />} />
            <Route path="bgcode" element={<BGCode />} />
        </Route>
        <Route
            path="/user"
            element={
                <ProtectedRoute
                    redirectPath="/signin"
                    isRole={pageAuth.USER.includes(userRole) || pageAuth.USER.includes(role)}
                >
                    <User />
                </ProtectedRoute>
            }
        />
        <Route
            path="/error"
            element={
                <ProtectedRoute
                    redirectPath="/signin"
                    isRole={pageAuth.USER.includes(userRole) || pageAuth.USER.includes(role)}
                >
                    <Error />
                </ProtectedRoute>
            }
        />
        <Route path="*" element={<NotFoundView />} />
    </Routes>
}

export default RoutesIndex