import {PackNumberActionType} from "../../actions";

const initState = {
    allPackNumbers:[],
    packNumberByBGCode:{}
}

export const packNumberReducer = (state=initState , action = {}) => {
    switch (action.type) {
        case PackNumberActionType.Fetch_Pack_Number:
            return { ...state, allPackNumbers: action.payload}
        case PackNumberActionType.Pack_Number_By_BGCode:
            return { ...state, packNumberByBGCode: action.payload}
        default:
            return state
    }
};
export default packNumberReducer