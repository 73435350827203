export const userRoles = {
    ADMIN:1,
    STAFF:2,
    CUSTOMER:3,
    WAREHOUSE_MANAGER:4
}

export const pageAuth = {
    USER:[userRoles.ADMIN,userRoles.WAREHOUSE_MANAGER],
    AWB:[userRoles.ADMIN,userRoles.WAREHOUSE_MANAGER],
    REPORT:[userRoles.ADMIN,userRoles.WAREHOUSE_MANAGER],
    ERROR:[userRoles.ADMIN,userRoles.WAREHOUSE_MANAGER]
}
