import React, {useEffect, useState} from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,

} from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Draggable from 'react-draggable';
import {noop} from "lodash";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Autocomplete} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import {renderTimeViewClock} from "@mui/x-date-pickers";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {addAWB, editAWB, onErrorAlert, onSuccessAlert, searchAWB} from "../../actions";

const AwbEditor = ({search=  { pageNum:1, pageSize:10 },isEdit = false, data = null ,openModal,setOpenModal = noop ,handleClose = noop}) => {

    const id = data?.awbId
    const [selectWarehouse, setSelectWarehouse] = useState(null)
    const [cnStatus,setCnStatus] =  useState(null)
    const [eta,setETA] = useState(null)
    const schema = yup.object().shape({})
    const allWarehouse = useSelector(state => state?.warehouse.allWarehouse)
    const dispatch = useDispatch()
    const {register,reset,resetField,handleSubmit, formState: {errors}, setError, setValue} = useForm({
        resolver: yupResolver(schema),
        reValidateMode: 'onChange'
    })

    const [formLoading,setFormLoading] = useState(false)

    function onReFetchSuccess() {
        setFormLoading(false)
    }

    function onAddAWBSuccess(msg) {
        dispatch(searchAWB(search,onReFetchSuccess = noop,onError = noop))
        dispatch(onSuccessAlert(msg))
    }

    function onError(msg) {
        dispatch(onErrorAlert(msg))
    }

    function onEditAWBSuccess(msg) {

        dispatch(searchAWB(search,onReFetchSuccess = noop,onError = noop))
        dispatch(onSuccessAlert(msg))
    }


    const onSubmit = (data) => {

        let formData = {}
        formData.eta = moment(eta).format()
        formData.route = data?.route
        formData.airport = data?.airport
        formData.whId = selectWarehouse?.whId
        formData.awbCode = data?.awbCode
        formData.awbPcs = Number(data?.awbPcs)
        formData.pkgPcs = Number(data?.pkgPcs)
        formData.statusincn = cnStatus.id

        const axiosPostAdd = async () => {
            setFormLoading(true)
            await dispatch(addAWB(formData,onAddAWBSuccess,onError))
        }

        const axiosPostEdit = async () => {
            setFormLoading(true)
            formData.awbId = id
            await dispatch(editAWB(formData,onEditAWBSuccess,onError))
        }

        if(isEdit){
            axiosPostEdit().then()
        } else {
            axiosPostAdd().then()
        }


    }

    useEffect(()=>{
        reset()
        if(!isEdit){
            setETA(null)
            setSelectWarehouse(null)
            setCnStatus(null)
        }
        if(data!==null && isEdit){

            if(data?.warehouse!==undefined && data?.warehouse!==null){
             setSelectWarehouse(data.warehouse)
            } else {

                setSelectWarehouse(null)
            }

            if(data?.statusincn!==undefined && data?.statusincn!==null){
                setCnStatus(data.statusincn === '00'?{id:'00',status:'未发送'}:{id:'01',status:'已发送'})
            } else {
                setCnStatus(null)
            }


            if(data?.eta!==undefined && data?.eta!==null){
                setETA(moment(data.eta))
            } else {
                setETA(null)
            }
        }
    },[data,openModal])

    function PaperComponent(props) {
        return (
            <Draggable
                handle="#draggable-dialog-title"
                cancel={'[class*="MuiDialogContent-root"]'}
            >
                <Paper sx={{width:'50vw'}} {...props} />
            </Draggable>
        );
    }
    const renderModal = () =>
        <Dialog
            open={openModal}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle style={{ cursor: 'move', background:'#ff9800',fontWeight:'bold'}} >
                {isEdit?`Edit AWB:  ${data?.awbCode}`:'Add'}
            </DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)} style={{width: '100%'}}>
                <DialogContent >
                        <Grid item container xs={12} mt={1} spacing={2}>
                            <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <TextField
                                    required
                                    sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                                    {...register('awbCode')}
                                    variant='outlined'
                                    label='AWB' name='awbCode' fullWidth
                                    error={Boolean(errors.awb?.message)}
                                    helperText={errors.awb?.message}
                                    color="warning"
                                    defaultValue={isEdit?data.awbCode:''}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}  sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                                <FormControl fullWidth color='warning' >
                                    <Autocomplete
                                        required
                                        {...register('warehouse')}
                                        disablePortal
                                        options={allWarehouse}
                                        getOptionLabel={(option) => `${option?.whId}. ${option?.whName}`}
                                        isOptionEqualToValue={(option, value) => option?.whId === value?.whId}
                                        value={selectWarehouse}
                                        onChange={(e, v) => setSelectWarehouse(v)}
                                        renderInput={(params) =>
                                            <TextField
                                                {...register('warehouse')}
                                            color='warning' {...params}
                                            label="Warehouse" />}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={12} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <LocalizationProvider dateAdapter={AdapterMoment}
                                                      sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <DateTimePicker
                                        required
                                        sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                                        views={['year', 'day', 'hours', 'minutes', 'seconds']}
                                        label='ETA'
                                        slotProps={{textField: {color: 'warning', fullWidth: 'true'}}}
                                        value={eta}
                                        onChange={(date) => setETA(date)}

                                    />
                                </LocalizationProvider>
                            </Grid>

                            <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <TextField
                                    required
                                    sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                                    {...register('awbPcs')}
                                    variant='outlined'
                                    label='AWB Pcs' name='awbPcs' fullWidth
                                    type="number"
                                    // error={Boolean(errors.awb?.message)}
                                    // helperText={errors.awb?.message}
                                    color="warning"
                                    defaultValue={isEdit?data.awbPcs:null}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <TextField
                                    required
                                    sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                                    {...register('pkgPcs')}
                                    variant='outlined'
                                    label='PKG Pcs' name='pkgPcs' fullWidth
                                    type="number"
                                    // error={Boolean(errors.awb?.message)}
                                    // helperText={errors.awb?.message}
                                    color="warning"
                                     defaultValue={isEdit?data.pkgPcs:null}
                                />
                            </Grid>

                            <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <TextField
                                    required
                                    sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                                    {...register('route')}
                                    variant='outlined'
                                    label='Route' name='route' fullWidth
                                    // error={Boolean(errors.awb?.message)}
                                    // helperText={errors.awb?.message}
                                    color="warning"
                                    defaultValue={isEdit?data.route:''}
                                />
                            </Grid>

                            <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <TextField
                                    required
                                    sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                                    {...register('airport')}
                                    variant='outlined'
                                    label='Port' name='airport' fullWidth
                                    // error={Boolean(errors.awb?.message)}
                                    // helperText={errors.awb?.message}
                                    color="warning"
                                    defaultValue={isEdit?data.airport:''}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}  sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                                <FormControl fullWidth color='warning' >
                                    <Autocomplete
                                        required
                                        {...register('cnStatus')}
                                        disablePortal
                                        options={[{id:'00',status:'未发送'},{id:'01',status:'已发送'}]}
                                        getOptionLabel={(option) => `${option.status}`}
                                        isOptionEqualToValue={(option, value) => option === value.id}
                                        value={cnStatus}
                                        onChange={(e, v) => setCnStatus(v)}
                                        renderInput={(params) =>
                                            <TextField
                                                {...register('statusincn')}
                                                name='statusincn'
                                                color='warning' {...params}
                                                label="Status In CN" />}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                </DialogContent>

                <DialogActions>
                    <Button type='submit'  variant='outlined' color='warning' >
                        {isEdit?'Edit':'Add'}
                    </Button>
                    <Button variant='outlined' color='warning' onClick={handleClose}> cancel</Button>
                </DialogActions>
            </form>
        </Dialog>

    return <React.Fragment>
        {renderModal()}

    </React.Fragment>
}

export default AwbEditor