
export const MessageActionType = {
    Success:'Success',
    Error:'Error',
    Clear:'Clear'
}

export const onSuccessAlert = msg => dispatch => {
    dispatch({
        type:MessageActionType.Success,
        payload: msg
    })
}

export const onErrorAlert = msg => dispatch => {
    dispatch({
        type:MessageActionType.Error,
        payload: msg
    })
}


export const onClearAlert = msg => dispatch => {
    dispatch({
        type:MessageActionType.Clear
    })
}

