import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {useDispatch} from "react-redux";
import {login, onErrorAlert, onSuccessAlert} from "../../actions";
import {useNavigate} from "react-router";

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://www.darwynnfulfillment.com/">
                Darwynn Ltd.
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignInSide() {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const schema = yup.object().shape({
        username:yup.string().required('username is required'),
        password:yup.string().min(1).required('password is required')
    })

    const {register, reset, resetField, handleSubmit, formState: {errors}, setError,getValues, setValue,watch} = useForm({
        resolver: yupResolver(schema),
        reValidateMode: 'onChange'
    })

    function onSuccess(data) {
        dispatch(onSuccessAlert('Login Successfully'))
        navigate('/dashboard')
    }

    function onError(msg) {
        dispatch(onErrorAlert(msg))
    }

    const onSubmit = (data) => {
        dispatch(login(data,onSuccess,onError))
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid container component="main" sx={{ height: '100vh' }}>

                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: 'url(https://source.unsplash.com/random?wallpapers)',
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
                <Grid item xs={12} sm={8} md={5}  component={Paper} elevation={6} square sx={{position:'relative'}}>
                    <img src={window.location.origin +'/static/images/darwynn_logo.svg'} alt={'darwynn_logo'} style={{position:'absolute',right:2,top:10}}/>

                    <Box
                        sx={{
                            my: 10,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',

                        }}
                    >
                        <Avatar sx={{ mt: 8, mb:1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                       <Typography component="h1" variant="h5" >
                            Login to
                        </Typography>
                        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                label="User Name"
                                name="username"
                                autoFocus
                                {...register('username')}
                                error={Boolean(errors.username?.message)}
                                helperText={errors.username?.message}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                {...register(('password'))}
                                error={Boolean(errors.password?.message)}
                                helperText={errors.password?.message}
                            />
                            {/*<FormControlLabel*/}
                            {/*    control={<Checkbox value="remember" color="primary" />}*/}
                            {/*    label="Remember me"*/}
                            {/*/>*/}
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Sign In
                            </Button>

                            <Copyright sx={{ mt: 5 }} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}