import React, {useEffect, useRef, useState} from 'react'
import {
    Autocomplete,
    Box,
    Button, Fade,
    FormControl,
    FormLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup, Switch, Tooltip,
    useTheme
} from "@mui/material";
import Header from "../../components/dashboard/Header";
import Divider from "@mui/material/Divider";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextField from "@mui/material/TextField";
import {tokens} from "../../theme";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Barcode from "react-barcode";
import {useReactToPrint} from "react-to-print";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchAllStaff,
    fetchAllWarehouses, fetchError,
    fetchLabel, fetchPackNumber,
    fetchUserSum,
    onErrorAlert,
    onSuccessAlert
} from "../../actions";
import CircularProgress from "@mui/material/CircularProgress";
import {useDebounce} from "use-debounce";
import ScrollPaperDialog from "../../components/dialog/ScrollPaperDialog";
import moment from "moment";
import PrintIcon from '@mui/icons-material/Print';


const Error = () => {
    const loginRole = localStorage.getItem('user_role')
    const loginWhId = localStorage.getItem('whId')
    const theme = useTheme()
    const colors = tokens(theme.palette.mode);
    const schema = yup.object().shape({})
    const dispatch = useDispatch()
    const [packnumber, setPacknumber] = useState(null)
    const [computer, setComputer] = useState('A')
    const [staffLoading, setStaffLoading] = useState(false)
    const [warehouseLoading, setWarehouseLoading] = useState(false)
    const [labelLoading, setLabelLoading] = useState(false)
    const [scannerFinish, setScannerFinish] = useState(0)
    const [labelerFinish, setLabelFinish] = useState(0)
    const [scanner, setScanner] = useState(null)
    const [scanField, setScanField] = useState('')
    const [labeler, setLabeler] = useState(null)
    const [selectWarehouse, setSelectWarehouse] = useState(null)
    const [shipfrom, setShipFrom] = useState(null)
    const [returnto, setReturnTo] = useState(null)
    const [codeType, setCodeType] = useState(1)
    const [printType, setPrintType] = useState(1)
    const [labelType, setLabelType] = useState(1)
    const allUsers = useSelector(state => state?.user?.allStaff)
    const allWarehouse = useSelector(state => state?.warehouse.allWarehouse)
    const parcel = useSelector(state => state?.label.parcel)
    const [printStatus,setPrintStatus] = useState('ready')
    const [helpOpen,setHelpOpen] = useState(false)
    const [packNumberSize,setPackNumberSize] = useState(null)
    const [checked, setChecked] = React.useState(true);
    const [disableWh,setDisableWh] = useState(false)
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(schema),
        reValidateMode: 'onChange'
    })
    const labelComponentRef = useRef();
    const packNumberRef = useRef()

    useEffect(() => {
        const axiosGetAllStaffsAndWhs = async () => {

            setWarehouseLoading(true)
            await dispatch(fetchAllWarehouses(onWarehouseSuccess, onError))
        }
        axiosGetAllStaffsAndWhs().then()

    }, [])


    useEffect(() => {
        if(printType==3 && printStatus === 'success') {
            dispatch(onSuccessAlert('update pack number successfully'))
        }
        if(!labelLoading && printStatus === 'success' && printType!=3 )
        {

            handlePrint()
        }

        //error

    }, [labelLoading]);

    useEffect(() => {

        if (scanner) {
            const axiosPostWorkSum = async () => {
                await dispatch(fetchUserSum({scanner: scanner?.uid}, onScannerChangeSuccess, onError))
            }
            axiosPostWorkSum().then()
        }


    }, [scanner]);



    useEffect(() => {

        if (labeler) {
            const axiosPostWorkSum = async () => {
                await dispatch(fetchUserSum({labeller: labeler?.uid}, onLabelerChangeSuccess, onError))
            }
            axiosPostWorkSum().then()
        }

    }, [labeler]);


    const [debouncedValue] = useDebounce(scanField, 400)

    const onSubmit = (data) => {
        setStaffLoading(false)
    }

    useEffect(() => {
        selectWarehouse ? setShipFrom(selectWarehouse?.shipfrom) : setShipFrom(null)
        selectWarehouse ? setReturnTo(selectWarehouse?.returnto) : setReturnTo(null)

        if(selectWarehouse){
            const postGetStaff = async () => {
                setStaffLoading(true)
                await dispatch(fetchAllStaff(selectWarehouse['whId'],onStaffSuccess, onError))
            }

            postGetStaff(selectWarehouse.whId).then()

            postGetStaff().then()
        }
        //pack number generate

    }, [selectWarehouse]);

    function onPackNumbersGetSuccess(data) {
        const uniquePackNumber = new Set(data.map(item => item.packnumber));
        if(uniquePackNumber.size >0) {
            let zeroAdd = 3-((uniquePackNumber.size).toString().length)
            let string = '0'.repeat(zeroAdd) + (uniquePackNumber.size).toString()
            setPackNumberSize(string)
        } else {
            setPackNumberSize('001')
        }

    }

    useEffect(()=>{
        if(selectWarehouse && computer){
            const axiosPostGetPackNumbers = async () => {
                let formData = {}
                let arr = []
                formData.date = moment().format('YYYY-MM-DD')
                formData.whId = selectWarehouse.whId
                formData.packNumberList = [...arr,computer]
                await dispatch(fetchPackNumber(formData,onPackNumbersGetSuccess,onError))
            }
            axiosPostGetPackNumbers().then()
        } else {
            setPackNumberSize(null)
        }

    },[selectWarehouse,computer])

    useEffect(()=>{
        if(packNumberSize && selectWarehouse && computer){
            let pk = selectWarehouse.whName + '-' + moment().format('YYYYMMDD') + computer + packNumberSize
            setPacknumber(pk)
        } else {
            setPacknumber(null)
        }

    },[packNumberSize,computer])


    function onScannerChangeSuccess(data) {
        setScannerFinish(data)
    }


    function onLabelerChangeSuccess(data) {
        setLabelFinish(data)
    }

    const onError = (msg) => {
        setPrintStatus('error')
        dispatch(onErrorAlert(msg))
        setScanField('')
        setWarehouseLoading(false)
        setLabelLoading(false)
        setStaffLoading(false)
    }

    const onStaffSuccess = () => {
        setStaffLoading(false)
    }

    function onWarehouseSuccess(data) {
        if(loginWhId && loginWhId!=='null'){
            setSelectWarehouse(()=>data.find(item=>item.whId === Number(loginWhId)))
            setDisableWh(true)
        }
        setWarehouseLoading(false)
    }

    function nextHandler() {
        if(packNumberSize){
            let numberValue = parseInt(packNumberSize, 10) + 1;
            let zeroAdd = 3-(numberValue.toString().length)
            let string = '0'.repeat(zeroAdd) + numberValue.toString()
            setPackNumberSize(string)

        }
    }



    const handlePrint = useReactToPrint({
        content: () => labelComponentRef.current,
        onAfterPrint: () => {
            setScanField('')
            document.getElementById('scanField').focus()
        },
        pageStyle: `@media print {
                  header, footer, aside, form, … {
                    display: none;
                  }
                  article {
                    width:100%!important;
                    height:100%!important;
                    padding:0% !important;
                    margin:0!important;
                  }
                }
                @page {
                  margin: 0.5cm;
                }`
    });

    const handlePackNumberPrint = useReactToPrint({
        content: () => packNumberRef.current,
        pageStyle: `@media print {
                    
                  header, footer, aside, form, … {
                    display: none;
                  }
                  article {
                    display:block
                    width:100%!important;
                    height:100%!important;
                    padding:0% !important;
                    margin:0!important;
                 
                  }
                }
                `
    });


    //onSuccess for
    function onLabelSuccess(data) {
        setPrintStatus('error success')
        dispatch(onSuccessAlert('Error handling Successfully'))
        setScanField('')
        setLabelLoading(false)
    }

    // send post to back
    useEffect(() => {

        if (scanner && labeler && selectWarehouse && packnumber !== '' && packnumber !== null && debouncedValue !== '') {
            let bgCode = debouncedValue
            //check scan twice
            if(debouncedValue.length> 35 && debouncedValue.length < 50 && debouncedValue.length % 2 === 0){
                const halfLength = debouncedValue.length / 2;
                const firstPart = debouncedValue.substring(0, halfLength);
                const secondPart = debouncedValue.substring(halfLength);
                if (firstPart === secondPart){
                    bgCode = firstPart
                }
            }

            //check scan triple
            if(debouncedValue.length> 50 && debouncedValue.length % 3 === 0){
                const partLength = debouncedValue.length / 3;
                const firstPart = debouncedValue.substring(0, partLength);
                const secondPart = debouncedValue.substring(partLength, 2 * partLength);
                const thirdPart = debouncedValue.substring(2 * partLength);
                if (firstPart === secondPart && secondPart === thirdPart){
                    bgCode = firstPart
                }
            }

            const data = {}
            data.scanner = scanner?.uid
            data.labeller = labeler?.uid
            data.shipFrom = selectWarehouse?.shipfrom
            data.returnTo = selectWarehouse.returnto
            data.codeType = String(codeType)
            data.printType = String(printType)
            data.packNumber = packnumber
            data.bgCode = debouncedValue
            data.whId = selectWarehouse.whId
            data.whName = selectWarehouse.whName
            data.labelType = String(labelType)
            const axiosPostLabel = async () => {
                setLabelLoading(true)
                await dispatch(fetchError(data, onLabelSuccess, onError))
            }
            axiosPostLabel().then()
        }

    }, [debouncedValue]);


    function handleScannerChange(e, v) {
        setScanner(v)
        setScanField('')
    }

    function handleLaberlerChange(e, v) {
        setLabeler(v)
        setScanField('')
    }

    function handleWarehouseChange(e, v) {
        setSelectWarehouse(v)
        setScanField('')
    }

    function handlePacknumberChange(e) {
        setPacknumber(e.target.value)
        setScanField('')
    }

    function handlePrintType(e) {
        setPrintType(e.target.value)
        setScanField('')
    }

    function handleLabelType(e) {
        setLabelType(e.target.value)
    }

    function handleChangeCheck(event) {
        setChecked(event.target.checked);
    }

    const renderForm = () =>

        <Grid item md={12} xs={12} lg={6} sx={{background: colors.primary[400]}} p={2}>
            <form onSubmit={handleSubmit(onSubmit)} style={{width: '100%'}}>
                <Grid item container xs={12} spacing={2}>
                    {/*scanner*/}
                    <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <FormControl fullWidth color='warning'>
                            <Autocomplete
                                required
                                disablePortal
                                options={allUsers}
                                getOptionLabel={(option) => `${option?.name}`}
                                isOptionEqualToValue={(option, value) => option?.uid === value?.uid}
                                value={scanner}
                                onChange={handleScannerChange}
                                renderInput={(params) =>
                                    <TextField
                                        color='warning' {...params}
                                        label="Scanner"/>}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <FormControl fullWidth color='warning'>
                            <Autocomplete
                                required
                                disablePortal
                                options={allUsers}
                                getOptionLabel={(option) => `${option?.name}`}
                                isOptionEqualToValue={(option, value) => option?.uid === value?.uid}
                                value={labeler}
                                onChange={handleLaberlerChange}
                                renderInput={(params) =>
                                    <TextField
                                        color='warning' {...params}
                                        label="Labeler"/>}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {`Today Finish: ${scannerFinish}`}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {`Today Finish: ${labelerFinish}`}
                    </Grid>

                    {/*warehouse*/}
                    <Grid item xs={12} md={12} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <FormControl fullWidth color='warning'>
                            <Autocomplete
                                required
                                disablePortal
                                options={allWarehouse}
                                disabled={disableWh}
                                getOptionLabel={(option) => `${option?.whName}`}
                                isOptionEqualToValue={(option, value) => option?.whId === value?.whId}
                                value={selectWarehouse}
                                onChange={handleWarehouseChange}
                                renderInput={(params) =>
                                    <TextField
                                        color='warning' {...params}
                                        label="Warehouse"/>
                                }
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={12} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <FormControl fullWidth color='warning'>
                            <FormLabel sx={{color: '#ed6c02'}}>
                                Ship From:
                            </FormLabel>
                            {shipfrom &&
                                <Typography>
                                    {shipfrom}
                                </Typography>}
                        </FormControl>
                    </Grid>


                    <Grid item xs={12} md={12} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <FormControl fullWidth color='warning'>
                            <FormLabel sx={{color: '#ed6c02'}}>
                                Return To:
                            </FormLabel>
                            {returnto &&
                                <Typography>
                                    {returnto}
                                </Typography>}
                        </FormControl>
                    </Grid>
                    {/*codetype*/}
                    <Grid item xs={12} md={12} sx={{display: 'flex', alignItems: 'center', justifyContent: 'start'}}>

                        <FormLabel sx={{marginRight: '20px'}}>Code Type</FormLabel>
                        <RadioGroup
                            aria-label="fff"
                            name="codetype"
                            value={codeType}
                            onChange={(e) => setCodeType(e.target.value)}
                            row={true}
                            defaultValue="bgcode"
                        >
                            <FormControlLabel value="1" control={<Radio color="warning"/>} label="BG Code"/>
                            <FormControlLabel value="2" disabled control={<Radio color="success"/>}
                                              label="SS Tracking Nunber"/>

                        </RadioGroup>


                    </Grid>
                    {/*print type*/}
                    <Grid item xs={12} md={12} sx={{display: 'flex', alignItems: 'center', justifyContent: 'start'}}>
                        <FormLabel sx={{marginRight: '24px'}}>Print Type </FormLabel>
                        <RadioGroup
                            aria-label="fff"
                            name="printtype"
                            value={printType}
                            onChange={handlePrintType}
                            row={true}
                            defaultValue="first"
                        >
                            <FormControlLabel value="1" control={<Radio color="warning"/>} label="First Printing"/>
                            <FormControlLabel value="2" control={<Radio color="warning"/>} label="Reprinting"/>
                            <FormControlLabel value="3" control={<Radio color="warning"/>}
                                              label="Update Pack Number"/>
                        </RadioGroup>
                    </Grid>

                    {/*label type*/}

                    <Grid item xs={12} md={12} sx={{display: 'flex', alignItems: 'center', justifyContent: 'start'}}>
                        <FormLabel sx={{marginRight: '19px'}}>Exception Package Handling</FormLabel>
                        <RadioGroup
                            aria-label="fff"
                            name="labeltype"
                            value={labelType}
                            onChange={handleLabelType}
                            row={true}
                            defaultValue="first"
                        >
                            <Tooltip TransitionComponent={Fade}
                                     TransitionProps={{ timeout: 600 }}
                                     placement="top" title="Handle Exception Parcel Immediately"><FormControlLabel value="1" control={<Radio color="warning"/>} label="Immediate Handling"/></Tooltip>
                            <Tooltip TransitionComponent={Fade}
                                     TransitionProps={{ timeout: 600 }}
                                     placement="top" title="Handle Exception Parcel Deferredlly"><FormControlLabel value="2" control={<Radio color="warning"/>} label="Deferred Handling"/></Tooltip>

                        </RadioGroup>
                    </Grid>

                    {/*pack number generate*/}

                    <Grid container item  spacing={1} xs={12} md={12} sx={{display: 'flex', alignItems: 'center', justifyContent: 'start'}}>

                        <Grid item >
                            <FormLabel sx={{color: '#ed6c02'}}>
                                Pack Number Generator:
                            </FormLabel>
                        </Grid>
                        <Grid item >
                            <FormControlLabel control={
                                <Switch
                                    color="warning"
                                    checked={checked}
                                    onChange={handleChangeCheck}
                                />

                            }
                                              label="Automatic" />
                        </Grid>
                        {
                            checked ?
                                <Grid item container spacing={1} xs={12} alignItems={'center'}>
                                    <Grid item xs={2}>{selectWarehouse?.whName}</Grid>
                                    <Grid item xs={2}>{moment().format('YYYYMMDD')}</Grid>
                                    <Grid item xs={3}>
                                        <Autocomplete
                                            disablePortal
                                            size="small"
                                            sx={{width:'60%'}}
                                            options={['A','B','C','D','E','F','G','H','I','J','K',"L",'M','N']}
                                            value={computer}
                                            onChange={ (e,v) => setComputer(v)}
                                            renderInput={(params) => (
                                                <TextField {...params} color='warning' label="Computer" />
                                            )}
                                        />

                                    </Grid>
                                    <Grid item xs={1}>{packNumberSize}</Grid>
                                    <Grid item xs={3}>
                                        <Button variant={'outlined'} color={'warning'} onClick={nextHandler}>
                                            Generate New
                                        </Button>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton variant={'outlined'}
                                                    color={'warning'}
                                                    onClick={handlePackNumberPrint}
                                        >
                                            <PrintIcon/>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                :<Grid item xs={12}>
                                    <TextField
                                        sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                                        variant='outlined'
                                        label='Pack Number' name='packnumber' fullWidth
                                        value={packnumber}
                                        onChange={handlePacknumberChange}
                                        // error={Boolean(errors.awb?.message)}
                                        // helperText={errors.awb?.message}
                                        color="warning"
                                    />
                                </Grid>
                        }


                    </Grid>
                    <Grid item xs={12} md={12} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                        <TextField
                            autoFocus = {!labelLoading}
                            sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                            {...register('scan')}
                            variant='outlined'
                            id='scanField'
                            label='Scan' name='scan' fullWidth
                            // error={Boolean(errors.awb?.message)}
                            // helperText={errors.awb?.message}
                            color="warning"
                            value={scanField}
                            onChange={(e) => setScanField(e.target.value)}
                        />
                    </Grid>

                </Grid>
            </form>
        </Grid>

    const renderLabel = () =>
        <Grid item xs={12} md={12} lg={5} sx={{height: '60vh', fontColor: 'black'}}>
            {labelLoading ? <CircularProgress color='warning'/> : <article ref={labelComponentRef} className={'print-container'} style={{
                width: '400px',
                background: 'white',
                height: '540px',
                marginLeft: '20px',
                padding: '5px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }}>
                <Grid container spacing={0} xs={12} sx={{
                    width: '100%',
                    height: '100%',
                    border: '2px solid black',
                    color: 'black',
                    fontWeight: 'bold',
                    fontFamily: ' "Arial Narrow", Arial, sans-serif'
                }}>
                    {/*row 1 ship and return*/}
                    <Grid container item xs={12} sx={{height: '20%', border: '1px solid black'}}>
                        <Grid item xs={6} sx={{
                            border: '1px solid black',
                            fontWeight: 'bold',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'start',
                            alignItem: 'start'
                        }}>
                            <Grid item sx={{color: 'black'}}>
                                Ship From:
                            </Grid>
                            <Grid item>
                                {shipfrom}
                            </Grid>
                        </Grid>
                        <Grid item xs={6} sx={{
                            border: '1px solid black',
                            fontWeight: 'bold',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'start',
                            alignItem: 'start'
                        }}>
                            <Grid item sx={{color: 'black' }}>
                                Return To:
                            </Grid>
                            <Grid item>
                                {returnto}
                            </Grid>
                        </Grid>
                    </Grid>
                    {/*row 2 ship to and logo*/}
                    <Grid container item xs={12} sx={{height: '30%', border: '1px solid black'}}>
                        <Grid item xs={7} sx={{fontWeight: 'bold', display: 'flex', flexDirection: 'column'}}>
                            <div>Ship To:</div>
                            <div> {parcel?.name}</div>
                            <div> {parcel?.address1}</div>
                            <div> {parcel?.address2}</div>
                            <div> {`${parcel?.city} ${parcel?.stateProvince} ${parcel?.postalCode} ${parcel?.country} `}</div>
                            <br/>
                            {parcel?.contactPhone && <div>{`Tel: ${parcel?.contactPhone ? parcel?.contactPhone : ''}`}</div>}
                        </Grid>
                        <Grid item xs={5} sx={{display: 'flex', flexDirection: 'column'}}>
                            <Grid item sx={{height: "50%"}}>
                                <img src={window.location.origin + '/static/images/straight_logo.png'} width={'100%'}
                                     alt={'straight_logo'}/>
                            </Grid>
                            <Grid item sx={{textAlign: 'center'}}>
                                <Typography variant={'h1'} sx={{fontWeight: 600}}> {parcel?.airport} </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/*row3 package description*/}
                    <Grid item container xs={12} sx={{height: '22%', border: '1px solid black'}}>
                        <Grid item xs={9.5} sx={{
                            border: '1px solid black',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'start',
                            justifyContent: 'start'
                        }}>
                            <Grid item xs={12}>Carton 1 of 1</Grid>
                            <Grid item xs={12}>{`SKU: ${parcel?.sku ? parcel.sku : ''}`}</Grid>
                            <Grid item xs={12}>{`Order number: ${parcel?.orderNumber ? parcel?.orderNumber : ''}`}</Grid>
                            <Grid item xs={12}>{`Package Description: ${parcel?.packageDescription ? parcel?.packageDescription : ''}`}</Grid>
                        </Grid>
                        <Grid item xs={2.5}
                              sx={{border: '1px solid black', display: 'flex', alignItems: 'center', }}>
                            <Typography variant={'h6'}>STANDARD</Typography>
                        </Grid>

                    </Grid>
                    {/*row4 weight & dimensions*/}
                    <Grid item xs={12} sx={{
                        height: '8%',
                        border: '1px solid black',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        justifyContent: 'flex-start'
                    }}>
                        <Grid>
                            {`Weight: ${parcel?.weight ? parcel?.weight : ''} ${parcel?.weightUom ? parcel?.weightUom : ''}`}
                        </Grid>
                        <Grid>
                            {`Dimensions:  ${parcel?.length ? parcel?.length : ''} ${parcel?.length ? 'X' : ''} ${parcel?.width ? parcel?.width : ''} ${parcel?.length ? 'X' : ''} ${parcel?.height ? parcel?.height : ''} ${parcel?.dimensionsUom ? parcel?.dimensionsUom : ''}`}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{
                        height: '20%',
                        border: '2px solid black',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        { parcel?.ssCode ?<Barcode textPosition={'top'} width={1.3} height={40} value={parcel?.ssCode} format="CODE128"/>:'' }
                    </Grid>

                </Grid>
            </article>}
        </Grid>
    return <React.Fragment>

        <Box m="20px" container flexDirection='column'>
            {/* HEADER */}
            <Grid item container justifyContent="start" alignItems="center" >
                <Header title="Exceptional Parcel Handler"/>
            </Grid>
            <Grid item>
                <Divider variant="middle"/>
            </Grid>
            <Grid item container xs={12} alignItems='start' justifyContent='space-around' p={3}>
                {(staffLoading && warehouseLoading) ? <CircularProgress color='warning'/> : renderForm()}
                {renderLabel()}
            </Grid>
            <ScrollPaperDialog helpOpen={helpOpen} handleHelpClose={()=>setHelpOpen(false)} />
            <Box ref={packNumberRef} className="printpk" displayPrint='flex' sx={{
                width: '400px',
                height: '600px',
                background: 'white',
                padding: '5px',
                display:'none',
                justifyContent: 'center',
                alignItems: 'center',

            }}>
                <Typography sx={{color:'black'}} variant={'h2'}>{packnumber}</Typography>
            </Box>
        </Box>

    </React.Fragment>
}

export default Error