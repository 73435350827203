import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from 'react'
import {Autocomplete, Box, Button, Chip, FormControl, FormLabel, useTheme} from "@mui/material";
import Grid from "@mui/material/Grid";
import Header from "../../components/dashboard/Header";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import {DataGrid} from "@mui/x-data-grid";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {tokens} from "../../theme";
import TextField from "@mui/material/TextField";
import {userRoles} from "../../config/userRoleMapping";
import {fetchAllWarehouses, onErrorAlert, searchUsers} from "../../actions";
import UserEditor from "./UserEditor";


const userRolesArray = Object.entries(userRoles).map(([role, roleId]) => ({
    id: roleId,
    role: role,
    roleId: roleId
}));

const User = () => {
    const style = {
        page: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            justifyContent: 'centre',
            padding: 2,


        },
        container: {
            width: '100%',
            alignItems: 'center',

        },
        searchContainer: {
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 2,
        },

        packContainer:{
            width: '100%',
            alignItems: 'start',
            justifyContent: 'start',
            borderRadius: 2,
        },

        link:{
            display:'inline-block',
            padding:'10px 20px',
            textDecoration:'none',
            cursor:'pointer',
            color:'#ff9800',
            border:'1px solid #ff9800',
        }


    }
    const schema = yup.object().shape({})
    const {register, handleSubmit, formState: {errors}, setError, setValue} = useForm({
        resolver: yupResolver(schema),
        reValidateMode: 'onChange'
    })
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch()
    const loginRole = localStorage.getItem('user_role')
    const loginWhId = localStorage.getItem('whId')
    const allWarehouse = useSelector(state => state?.warehouse.allWarehouse)
    const userList = useSelector(state => state.user?.userList)
    const totalRows = useSelector(state => state.user?.totalRows)
    const [selectWarehouse, setSelectWarehouse] = useState(null)
    const [userRole, setUserRole] = useState(null)
    const [stableLoading,setTableLoading] = useState(false)
    const [warehouseLoading, setWarehouseLoading] = useState(false)
    const [pageSize,setPageSize] = useState(10)
    const [pageNum,setPageNum] = useState(1)
    const [search,setSearch] = useState({})
    const [openModal,setOpenModal] = useState(false)
    const [disableWh,setDisableWh] = useState(false)
    const [disableRole,setDisableRole] = useState(false)
    const [rows,setRows] = useState([])

    const columns = [
        {
            field:'uid',
            headerName:'Id',
            width:50,
        },
        {
            field:'name',
            headerName:'Name',
            width:170,
        },
        {
            field:'roleId',
            headerName:'User Role',
            width:230,
            renderCell: (params) => {
                return<>{findRoleByRoleId(params?.value)}</>
            }
        },
        {
            field:'whId',
            headerName:'Warehouse',
            width:150,
            renderCell: (params) => {
                return<>{params?.value === null ?'':allWarehouse.find(value => value.whId === params.value)?.whName}</>
            }
        },
        {
            field:'email',
            headerName:'Email',
            width:200,
        },
        {
            field:'mobile',
            headerName:'Cellphone',
            width:150,
        },
        {
            field:'gender',
            headerName:'Gender',
            width:90,
        },
        {
            field:'isActive',
            headerName:'Active',
            width:90,
        },
        { field: 'actions', headerName: 'Actions', width: 170, renderCell: (params) => {
                return (
                    <>
                        <Button
                            onClick={()=>{

                            }}
                            variant="contained"
                            color="warning"
                            sx={{marginRight:1}}
                        >
                            Edit
                        </Button>
                        <Button
                            onClick={()=>{
                            }}
                            variant="contained"
                            color="warning"
                        >
                            Inactive
                        </Button>
                    </>

                );
            }
        }

    ]


    useEffect(() => {

        const axiosPostAllWhs = async () => {
            let formData = {}
            formData.pageNum = pageNum
            formData.pageSize = pageSize
            if(loginWhId && loginWhId!=='null' && loginRole === '4'){
                formData.whId = Number(loginWhId)
                formData.roleId = 2
            }
            setWarehouseLoading(true)
            await dispatch(fetchAllWarehouses(onWarehouseSuccess, onError))
            setTableLoading(true)
            await dispatch(searchUsers(formData,onSearchSuccess,onError))

        }
        axiosPostAllWhs().then()

    }, [])

    useEffect(() => {
        const axiosPostAllUsers = async () => {

            let formData = {...search,pageNum,pageSize}
            setTableLoading(true)
            await dispatch(searchUsers(formData,onSearchSuccess,onError))

        }
        axiosPostAllUsers().then()

    }, [pageSize,pageNum]);


    //function

    function onSearchSuccess(data) {
        setRows(()=>[...data])
        setTableLoading(false)
    }

    const onSubmit = (data) => {
        let formData = {}
        if(selectWarehouse){
            formData.whId = selectWarehouse.whId
        }
        if(userRole){
            formData.roleId = userRole.id
        }
        if(data.name){
            formData.name = data.name
        }

        formData.pageNum = pageNum
        formData.pageSize = pageSize
        setSearch(formData)
        const axiosPostUsersSearch = async () => {
            setTableLoading(true)
            await dispatch(searchUsers(formData,onSearchSuccess,onError))
        }
        axiosPostUsersSearch().then()

    }

    function onWarehouseSuccess(data) {

        if(loginWhId && loginWhId!=='null'&& loginRole === '4'){
            setSelectWarehouse(data.find(item=>item.whId === Number(loginWhId)))
            setDisableWh(true)
            setUserRole(userRolesArray.find(item => item.id === 2))
            setDisableRole(true)
        }
        setWarehouseLoading(false)

    }

    function onError(msg) {
        dispatch(onErrorAlert(msg))
    }

    function handlePaginationChange(data) {

        setPageNum(()=> data.page+1)
        setPageSize(()=>data.pageSize)

    }

    const findRoleByRoleId = (roleId) => {
        const role = Object.keys(userRoles).find((key) => userRoles[key] === roleId);
        return role || null; // Return null if roleId is not found
    };

    const handleOpen = () => setOpenModal(true);
    const handleClose = () => {
        setOpenModal(false)
    }



    const renderSubmit = () =>
        <Grid container justifyContent='end' alignItems='center' spacing={2} sx={{width: '100%'}} mt={2}>
            <Grid item >
                <Button color='warning'
                        variant='contained'
                        type='submit'

                >
                    Search
                </Button>
            </Grid>

            <Grid item >
                <Button color='warning'
                        variant='contained'
                        onClick={()=>{
                            handleOpen()
                        }}
                >
                    add
                </Button>
            </Grid>


        </Grid>

    const renderForm = () =>
        <Grid item container justifyContent='center' alignItems='center' spacing={2} sx={{width: '100%'}}>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <FormLabel component='legend'>Search Fields</FormLabel>
                </FormControl>
            </Grid>

            <Grid item container xs={12} spacing={2}>
                <Grid item xs={12} md={4} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                    <TextField
                        sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                        {...register('name')}
                        variant='outlined'
                        label='Name' name='name' fullWidth
                        error={Boolean(errors.awb?.message)}
                        helperText={errors.awb?.message}
                        color="warning"
                    />
                </Grid>
                <Grid item xs={12} md={4}  sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                    <FormControl fullWidth color='warning' >
                        <Autocomplete
                            required
                            disablePortal
                            disabled={disableWh}
                            options={allWarehouse}
                            getOptionLabel={(option) => `${option?.whId}. ${option?.whName}`}
                            isOptionEqualToValue={(option, value) => option?.whId === value?.whId}
                            value={selectWarehouse}
                            onChange={(e, v) => setSelectWarehouse(v)}
                            renderInput={(params) =>
                                <TextField
                                    color='warning' {...params}
                                    label="Warehouse" />}

                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <FormControl fullWidth color='warning' >
                        <Autocomplete
                            required
                            disablePortal
                            disabled={disableRole}
                            options={userRolesArray}
                            getOptionLabel={(option) => `${option?.role}`}
                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                            value={userRole}
                            onChange={(e, v) => setUserRole(v)}
                            renderInput={(params) =>
                                <TextField
                                    color='warning' {...params}
                                    label="User Role" />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={3} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                </Grid>

            </Grid>

        </Grid>




    return (
        <React.Fragment>
            <Box m="20px"
                 sx={style.page}>
                {/* HEADER */}
                <Grid item container justifyContent="space-between" alignItems="center" >
                    <Header title="Users Management"/>
                </Grid>
                <Box>
                    <Divider variant="middle" />
                </Box>
                {/* GRID & CHARTS */}
                <Grid
                    container
                    display="flex"
                    flexDirection='column'
                    sx={style.container}

                >
                    <form onSubmit={handleSubmit(onSubmit)} style={{width: '100%'}}>
                        <Grid item container sx={style.searchContainer} p={2} backgroundColor={colors.primary[400]}>
                            {warehouseLoading?<CircularProgress/> :renderForm()}
                            {renderSubmit()}

                        </Grid>
                    </form>

                    <Grid item width='100%' mt={3} sx={{backgroundColor:colors.primary[400]}} p={1}>
                        <DataGrid
                            sx={{
                                border: 'none',
                                fontSize:'small',
                                fontWeight:'bold',
                                textAlign: 'center',
                                '& .MuiDataGrid-columnHeaderTitleContainer':{
                                    backgroundColor:'warning'
                                }

                            }}
                            getRowId={(row)=> row.uid}
                            rows={rows}
                            rowCount={totalRows}
                            columns={columns}
                            pageSizeOptions={[10,25,50]}
                            initialState={{
                                pagination: { paginationModel: { pageSize: 10 } },
                            }}
                            paginationMode={"server"}
                            onPaginationModelChange = {
                                handlePaginationChange
                            }
                            loading={stableLoading}
                        />
                    </Grid>
                    <UserEditor selectWarehouse={selectWarehouse} setSelectWarehouse={setSelectWarehouse} search={search} openModal={openModal} setOpenModal={setOpenModal} handleClose={handleClose}/>
                </Grid>
            </Box>
        </React.Fragment>
    )
}

export default User