import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion, AccordionActions, AccordionDetails, AccordionSummary,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton
} from "@mui/material";
import Typography from "@mui/material/Typography";
const ScrollPaperDialog = ({helpOpen,handleHelpClose}) => {

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (helpOpen) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [helpOpen]);

    return <React.Fragment>
        <Dialog
            sx={{zIndex:9999}}
            fullScreen={true}
            open={helpOpen}
            onClose={handleHelpClose}
            scroll='paper'
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="scroll-dialog-title" sx={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                <Typography variant="h3">
                    User Guide for Darwynn Label
                </Typography>
                <Grid>
                    <IconButton aria-label="delete" color="warning" onClick={handleHelpClose}>
                        <CloseIcon/>
                    </IconButton>
                </Grid>
            </DialogTitle>
            <DialogContent dividers={true}>
                <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}

                >
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            sx={{fontSize:'larger',color:'#ff9800'}}
                        >
                            #1. Setup Printer & Web Browser in First Time
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container flexDirection='column' spacing={1}>
                                <Grid item>1.Open the Firefox browser on your computer</Grid>
                                <Grid item>2.Navigate to the Darwynn Label SaaS platform using the provided URL.</Grid>
                                <Grid item>3.Configure default printer and label size as following</Grid>
                                <Grid item sx={{height:'800px'}}><img src={window.location.origin +'/static/images/login.png'} width='90%' height='100%'/></Grid>
                                <Grid item sx={{height:'800px'}}><img src={window.location.origin +'/static/images/print.png'} width='90%' height='100%'/></Grid>
                                <Grid item sx={{height:'800px'}}><img src={window.location.origin +'/static/images/printer.png'} width='90%' height='100%'/></Grid>
                                <Grid item sx={{height:'800px'}}><img src={window.location.origin +'/static/images/pt2.png'} width='90%' height='100%'/></Grid>
                                <Grid item>4.Press print button to print the page and discard the printed one </Grid>
                                <Grid item>5.After successfully setting up the printer, we proceed to configure the Firefox browser as follow.</Grid>
                                <Grid item>6.Open a new tab in Firefox and enter 'about:config' in the address bar.</Grid>
                                <Grid item sx={{height:'800px'}}><img src={window.location.origin +'/static/images/firefox1.png'} width='90%' height='100%'/></Grid>
                                <Grid item>7.Enable Firefox's silent printing mode to allow skipping print preview and directly initiate printing.</Grid>
                                <Grid item sx={{height:'800px'}}><img src={window.location.origin +'/static/images/firefox2.png'} width='90%' height='100%'/></Grid>
                                <Grid item>8.The browser settings are complete. Now, you can log in our page to print the labels.</Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2-content"
                            sx={{fontSize:'larger',color:'#ff9800'}}
                        >
                            #2. Replacing a printer on a computer, please follow the instructions below:
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container flexDirection='column' spacing={1}>
                                <Grid item>1.Change Firefox print_silent to false</Grid>
                                <Grid item sx={{height:'800px'}}><img src={window.location.origin +'/static/images/mode.png'} width='90%' height='100%'/></Grid>
                                <Grid item>2.Open firefox's print settings, choose the newly connected printer, and reconfigure as needed. Refer to the printer setup steps in the previous instruction (#1) for specific details.</Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleHelpClose} color='warning'>Done</Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>

}
export default ScrollPaperDialog