import React, {useState} from 'react'
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {FileUploader} from "../../components/fileUploader/FIleUploader";
import * as yup from "yup";
import {Alert, Box, Button, Grid} from "@mui/material";
import Header from "../../components/dashboard/Header";
import Divider from "@mui/material/Divider";
import axios from "axios";
import {import_original_url, label_scan_url, REACT_APP_API_URL} from "../../config/config";
import {LabelActionType, onErrorAlert, onSuccessAlert} from "../../actions";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
const Import = () => {
    const schema = yup.object().shape({})
    const [formData,setFormData] = useState(null)
    const dispatch = useDispatch()
    const [loading,setLoading] = useState(false)



    const {register,reset,resetField,handleSubmit, formState: {errors}, setError, setValue} = useForm({
        resolver: yupResolver(schema),
        reValidateMode: 'onChange'
    })
    const userId = useSelector(state => state.user.userId)

    async function doPostImportFile() {

        const data = new FormData()
        data.append('file',formData['file'])
        const url = `${REACT_APP_API_URL}${import_original_url}`
        const response = await axios.post(url, data, {
            headers:{
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Origin': "*"
            }
        })
            .then((res) => res)
            .catch(e => {
                dispatch(onErrorAlert('Server Error'))
            })
        if (response?.status === 200 && response.data.code === 200) {
            setLoading(false)
            dispatch(onSuccessAlert('Upload file successfully'))
            localStorage.setItem('last_upload',JSON.stringify(moment().format()))

        } else {
            dispatch(onErrorAlert(response?.data?.msg))
            setLoading(false)
        }
    }

    const onSubmit = async (data) => {
        if (formData) {
            setLoading(true)
            await doPostImportFile()
        }
    }

    function handleFile(data) {
        if(data.length === 0){
            setFormData(null)
        }else {
            setFormData({file:data[0]})
        }

    }

    return <React.Fragment>
        <Box m="20px"

             sx={{
                 display: 'flex',
                 flexDirection: 'column',
                 alignItems: 'start',
                 justifyContent: 'centre',
                 padding: 2,
             }}>
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Import"/>
            </Box>
            <Box>
                <Divider variant="middle"/>
            </Box>

            <Grid container flexDirection='column' alignItems='center' justifyContent='center' xs={12} mt={2}>
                <Alert severity="warning" variant="outlined" sx={{fontSize:'larger'}}> Please Avoid uploading duplicate files and do not close the webpage while uploading. Your patience is appreciated during the upload process.</Alert>
                <Typography sx={{marginTop:4 ,color:'#ed6c02'}}>{`Last Import: ${moment(JSON.parse(localStorage.getItem('last_upload'))).format('MMMM Do YYYY, h:mm:ss a')}` }</Typography>
                <form onSubmit={handleSubmit(onSubmit)} style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center', width: '100%',}}>
                    <Grid item container alignItems='center' justifyContent='center' xs={12} >
                        <FileUploader
                            asFormInputComponent={true}
                            handleImageCallBack ={handleFile}
                            list={[]}
                            onError={(e)=>{
                                console.log(e)}}
                            onSuccess={()=>{
                                console.log('success')
                            }}
                            multipleFile={false}
                        />
                    </Grid>
                    <Box sx = {{ m:1,position:'relative'}}>
                        <Button
                            variant="contained"
                            disabled={loading}
                            type='submit'
                            sx={{background:'#ed6c02'}}
                        >
                            Submit
                        </Button>
                        {loading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: '#ed6c02',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </Box>

                </form>
            </Grid>

        </Box>




    </React.Fragment>
}

export default Import