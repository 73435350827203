import React, {useEffect, useState} from 'react'
import {
    Autocomplete,
    Box,
    Button,
    Chip,
    FormControl,
    FormLabel, IconButton,
    InputAdornment,
    Modal,
    Stack,
    useTheme
} from "@mui/material";
import Header from "../../components/dashboard/Header";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import {DataGrid} from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {tokens} from "../../theme";
import {useDispatch, useSelector} from "react-redux";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from "moment";
import {fetchAllWarehouses, fetchPackNumber, onErrorAlert, searchAWB} from "../../actions";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CSVLink } from "react-csv";



const PackNumber = () => {
    const style = {
        page: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            justifyContent: 'centre',
            padding: 2,


        },
        container: {
            width: '100%',
            alignItems: 'center',

        },
        searchContainer: {
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 2,
        },

        packContainer:{
            width: '100%',
            alignItems: 'start',
            justifyContent: 'start',
            borderRadius: 2,
        },

        link:{
            display:'inline-block',
            padding:'10px 20px',
            textDecoration:'none',
            cursor:'pointer',
            color:'#ff9800',
            border:'1px solid #ff9800',
        }


    }
    const headers = [
        { label: "pack number", key: "packnumber" },
        { label: "awb code", key: "awb_code" },
        { label: "total per awb", key: "total_count_per_awb" },
        { label: "total per pack number", key: "total_count_sum_per_packnumber" },
        { label: "total by selected pack number", key: "total_count_sum_all" },
    ];
    const loginRole = localStorage.getItem('user_role')
    const loginWhId = localStorage.getItem('whId')

    const schema = yup.object().shape({})
    const {register, handleSubmit, formState: {errors}, setError, setValue} = useForm({
        resolver: yupResolver(schema),
        reValidateMode: 'onChange'
    })
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [warehouseLoading, setWarehouseLoading] = useState(false)
    const [selectWarehouse, setSelectWarehouse] = useState(null)
    const allWarehouse = useSelector(state => state?.warehouse.allWarehouse)
    const [date,setDate] = useState(moment())
    const [packArr,setPackArr] = useState([])
    const dispatch = useDispatch()
    const [packField,setPackField] = useState('')
    const allPackNumbers = useSelector(state => state?.packNumber.allPackNumbers)
    const [stableLoading,setTableLoading] = useState(false)
    const [disableWh,setDisableWh] = useState(false)


    const columns = [
        {
            field:'packnumber',
            headerName:'Pack Number',
            width:250,
        },
        {
            field:'awb_code',
            headerName:'AWB Code',
            width:250,
        },
        {
            field:'total_count_per_awb',
            headerName:'Count Per AWB',
            width:200,
        },
        {
            field:'total_count_sum_per_packnumber',
            headerName:'Count Per Pack Number',
            width:250,
        },
        {
            field:'total_count_sum_all',
            headerName:'Sum All Selected Pack Number',
            width:200,
        },

    ]


    useEffect(() => {
        const axiosPostAllWhsAndAWS = async () => {

            setWarehouseLoading(true)
            await dispatch(fetchAllWarehouses(onWarehouseSuccess, onError))
            setTableLoading(true)
            await dispatch(fetchPackNumber({'date':moment(date).format('YYYY-MM-DD'),'whId':loginWhId},onSearchSuccess,onError))
        }
        axiosPostAllWhsAndAWS().then()
    }, []);

    useEffect(() => {
        setPackField('')
    }, [packArr]);


    const onSubmit = async (data) => {
        let formData = {}
        if (date) {
            formData.date = moment(date).format('YYYY-MM-DD')
        }
        if (packArr.length > 0) {
            formData.packNumberList = [...packArr]
        }
        if (selectWarehouse) {
            formData.whId = selectWarehouse?.whId
        }

        await dispatch(fetchPackNumber(formData, onSearchSuccess, onError))

    }

    function onWarehouseSuccess() {
        if(loginWhId && loginWhId!=='null'&& loginRole === '4'){
            setSelectWarehouse(allWarehouse.find(item=>item.whId === Number(loginWhId)))
            setDisableWh(true)
        }

        setWarehouseLoading(false)
    }

    function onSearchSuccess() {
        setTableLoading(false)
    }

    function onError(msg) {
        dispatch(onErrorAlert(msg))
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.target.value && setPackArr([...packArr,event.target.value])
        }
    }

    const handleAddToArr = () => {
        packField && setPackArr([...packArr,packField])
    }

    const renderForm = () =>
        <Grid item container justifyContent='center' alignItems='center' spacing={2} sx={{width: '100%'}}>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <FormLabel component='legend'>Search Fields</FormLabel>
                </FormControl>
            </Grid>

            <Grid item container xs={12} spacing={2}>
                <Grid item xs={12} md={4} sx={{display: 'flex', alignItems: 'start', justifyContent: 'center'}}>
                    <TextField
                        sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                        {...register('packnumber')}
                        variant='outlined'
                        label='Pack Number' name='packnumber' fullWidth
                        error={Boolean(errors.awb?.message)}
                        helperText={'Click "Check" to include pack number(s) in the fuzzy search.'}
                        color="warning"
                        onKeyDown={handleKeyPress}
                        value={packField}
                        onChange={(event)=> setPackField(event.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <IconButton color={'warning'} onClick={handleAddToArr}>
                                        <CheckCircleIcon/>
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}

                    />
                </Grid>
                <Grid item xs={12} md={4}  sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                    <FormControl fullWidth color='warning' >
                        <Autocomplete
                            {...register('warehouse')}
                            disablePortal
                            disabled={disableWh}
                            options={allWarehouse}
                            getOptionLabel={(option) => `${option?.whId}. ${option?.whName}`}
                            isOptionEqualToValue={(option, value) => option?.whId === value?.whId}
                            value={selectWarehouse}
                            onChange={(e, v) => setSelectWarehouse(v)}
                            renderInput={(params) =>
                                <TextField
                                    {...register('warehouse')}
                                    color='warning' {...params}
                                    helperText={' '}
                                    label="Warehouse" />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <LocalizationProvider dateAdapter={AdapterMoment}
                                          sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <DatePicker
                            sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                            label='Date'
                            slotProps={{
                                textField: {helperText: 'MM/DD/YYYY',color: 'warning', fullWidth: 'true',},
                                actionBar: {
                                    actions: ['clear'],
                                },
                            }}
                            value={date}
                            onChange={( date) => setDate(date)}
                        />
                    </LocalizationProvider>
                </Grid>


            </Grid>

        </Grid>

    return <React.Fragment>
        <Box m="20px"
             sx={style.page}>
            {/* HEADER */}
            <Grid item container justifyContent="space-between" alignItems="center" >
                <Header title="Pack Number Report"/>
                <Box mb='30px' ml='20px'>
                    <CSVLink
                        data={allPackNumbers.map(({ id, ...rest }) => rest)}
                        filename={"temu.csv"}
                        target="_blank"
                        style={style.link}
                    >
                        Export to Excel
                    </CSVLink>
                </Box>
            </Grid>
            <Box>
                <Divider variant="middle"/>
            </Box>
            {/* GRID & CHARTS */}
            <Grid
                container
                display="flex"
                flexDirection='column'
                sx={style.container}

            >
                <form onSubmit={handleSubmit(onSubmit)} style={{width: '100%'}}>
                    <Grid item container sx={style.searchContainer} p={2} backgroundColor={colors.primary[400]}>
                        {warehouseLoading?<CircularProgress color="warning"/> :renderForm()}
                    </Grid>


                <Grid item container mt={2} sx={style.packContainer} p={3} flexDirection='column' backgroundColor={colors.primary[400]}>
                      <Grid item container xs={12} spacing={2} alignItems={'center'}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <FormLabel component='legend'>Entered Pack Number List</FormLabel>
                            </FormControl>
                        </Grid>
                        <Grid item container xs={12} md={10} spacing={1}>
                                {packArr.map((item,index) => (
                                    <Grid item key={index}>
                                        <Chip label={item} color="warning" onDelete={()=>{ setPackArr(packArr.filter(value => value !== item))}}/>
                                    </Grid>
                                ))}
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <Button type='submit' size={'large'} sx={{marginBottom:'2px'}} fullWidth={true} variant={'outlined'} color={'warning'} >Apply filter</Button>
                        </Grid>
                    </Grid>


                </Grid>
                </form>


                <Grid item width='100%' mt={3} sx={{backgroundColor:colors.primary[400]}} p={1}>
                    <DataGrid
                        sx={{
                            border: 'none',
                            fontSize:'small',
                            fontWeight:'bold',
                            textAlign: 'center',
                            '& .MuiDataGrid-columnHeaderTitleContainer':{
                                backgroundColor:'warning'
                            }
                        }}
                        getRowId={row => row.id}
                        rows={allPackNumbers}
                        columns={columns}
                        pageSizeOptions={[10,25,50]}
                        initialState={{
                            pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        loading={stableLoading}
                    />
                </Grid>

            </Grid>
        </Box>
    </React.Fragment>
}

export default PackNumber