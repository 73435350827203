import {useSelector} from "react-redux";
import {useMemo, useState} from "react";
import {createTheme, CssBaseline, StyledEngineProvider, ThemeProvider} from "@mui/material";
import {themeSettings} from "./theme";
import RoutesIndex from "./router";
import MuiSnackbar from "./components/public/Snackbar";
import React from 'react'



function App() {

  const mode = useSelector((state) => state.theme.mode);

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return (

      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <div className='app'>
                <RoutesIndex/>
                <MuiSnackbar/>
            </div>
        </ThemeProvider>
      </StyledEngineProvider>

  );
}

export default App;
