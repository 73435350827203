import {noop} from 'lodash';
import {mockDataTeam} from "../../mockData";
import axios from "axios";
import {
    add_user,
    label_staff_By_whId,
    label_staff_url,
    login_url,
    REACT_APP_API_URL,
    user_list,
    work_sum_url
} from "../../config/config";
import {LabelActionType} from "../label/labelAction";
export const UserActionType = {
    Check_Auth:'Check_Auth',
    Login_Success:'Login_Success',
    Login_Error:'Login_Error',
    Logout:'Logout',
    Fetch_All_Staff:'Fetch_All_Staff',
    Sync_UserRole:'Sync_UserRole',
    Sync_User:'Sync_User',
    Fetch_User_List:'Fetch_User_List'

}

export const login = (data,onSuccess = noop,onError = noop) => async dispatch => {

    const url = `${REACT_APP_API_URL}${login_url}`

    try {

        const response = await axios.post(url,data)
            .then((res) => res)
            .catch(e => {
                onError('SERVER ERROR 404')
            })

        if (response?.status === 200 && response.data.code === 200) {
            let token = response?.data?.data?.token
            localStorage.setItem("jwt_token",token)
            localStorage.setItem("user_role",response?.data?.data?.roleId)
            localStorage.setItem('user_name',response?.data?.data?.name)
            localStorage.setItem('whId',response?.data?.data?.whId)
            dispatch({
                type: UserActionType.Login_Success,
                payload: response.data.data
            })
            onSuccess(response?.data?.data)

        } else if(response?.status === 200 && response.data.code !== 200){
            onError(response.data.msg)
        }
    } catch {
         onError('Server Error')
    }

}

export const logout = (onSuccess = noop) => dispatch => {
    localStorage.removeItem('jwt_token')
    localStorage.removeItem('user_role')
    localStorage.removeItem('user_name')
    localStorage.removeItem('whId')
    dispatch({
        type:UserActionType.Logout,
    })
    if(!localStorage.getItem(('jwt_token'))){
        onSuccess()
    }
}
export const fetchAllStaff = (whId=null,onSuccess = noop,onError = noop) => async dispatch => {
    const url = `${REACT_APP_API_URL}${label_staff_By_whId}`
    try{
        const response = await axios.get(url,{ params: { whId: whId } })
            .then((res)=> res)
            .catch(e=>{

                onError('SERVER ERROR 404')
            })
        if (response?.status === 200 && response.data.code === 200 ){
            dispatch({
                type:UserActionType.Fetch_All_Staff,
                payload:response.data.data
            })
            onSuccess(response.data)

        } else {
            onError('Server Error')
        }
    }catch{
        onError('Server Error')
    }

}

export const fetchUserSum = (data,onSuccess,onError) => async dispatch => {
    const url = `${REACT_APP_API_URL}${work_sum_url}`
    const response = await axios.post(url,data)
        .then((res)=> res)
        .catch( e =>{
            onError(response.data.msg)
        })

    if (response?.status === 200 && response.data.code === 200 ){
        onSuccess(response.data.data)

    } else {

        onError(response.data.msg)
    }

}


export const syncUser = (data) => dispatch => {
    dispatch({
        type: UserActionType.Sync_User,
        payload: data
    })
}

export const searchUsers = (data, onSuccess = noop, onError = noop) => async dispatch => {
    const url = `${REACT_APP_API_URL}${user_list}`
    const response = await axios.post(url,data)
        .then((res)=> res)
        .catch( e =>{
            onError(response.data.msg)
        })
    if (response?.status === 200 && response.data.code === 200 ){
        dispatch({
            type:UserActionType.Fetch_User_List,
            payload:{list:response.data.rows,total:response.data.total}
        })
        onSuccess(response.data.rows)

    } else {
        onError(response.data.msg)
    }

}


export const addUser = (data, onSuccess = noop, onError = noop) => async dispatch => {
    const url = `${REACT_APP_API_URL}${add_user}`
    const response = await axios.post(url,data)
        .then((res)=> res)
        .catch( e =>{
            onError(response.data.msg)
        })
    if (response?.status === 200 && response.data.code === 200 ){
        onSuccess('create new user successfully')

    } else {
        onError(response.data.msg)
    }


}