import {noop} from "lodash";
import {
    label_scan_url,
    label_staff_url,
    pack_number_by_bgcode,
    pack_number_check,
    REACT_APP_API_URL
} from "../../config/config";
import axios from "axios";


export const PackNumberActionType = {
    Fetch_Pack_Number:'Fetch_Pack_Number',
    Pack_Number_By_BGCode:'Pack_Number_By_BGCode'
}


export const fetchPackNumber = (data, onSuccess = noop , onError = noop) => async dispatch => {
    const url = `${REACT_APP_API_URL}${pack_number_check}`
    const response = await axios.post(url,data)
        .then((res)=> res)
        .catch(e=>{
            onError(e)
        })
    if (response?.status === 200 && response.data.code === 200 ){
        const dataArrayWithId = response.data.data.map((item, index) => {
            return { ...item, "id": index+1 };
        });
        dispatch({
            type:PackNumberActionType.Fetch_Pack_Number,
            payload:dataArrayWithId
        })
        onSuccess(response.data.data)

    } else {
        onError(response.data.msg)
    }

}


export const fetchPackNumberByBGCode = (data, onSuccess = noop, onError = noop) => async dispatch => {
    const url = `${REACT_APP_API_URL}${pack_number_by_bgcode}?bgCode=${data.bgCode}`
    const response = await axios.get(url)
        .then((res)=> res)
        .catch(e=>{
            onError(e)
        })

    if (response?.status === 200 && response.data.code === 200 ){

        dispatch({
            type:PackNumberActionType.Pack_Number_By_BGCode,
            payload:response.data.data
        })
        onSuccess(response.data.data)

    } else {
        onError(response.data.msg)
    }

}