
//正式环境
export const REACT_APP_API_URL = "https://label.darwynnfulfillment.com/prod-api";

//test环境
//export const REACT_APP_API_URL = "https://labelt.darwynnfulfillment.com/prod-api";
//本地环境
//export const REACT_APP_API_URL = "http://192.168.168.21:38080";
// GET ALL Staff
export const label_staff_url= "/system/tl/user/getAllStaff";

export const label_staff_By_whId = "/system/tl/user/getUserList"

// Post
export const label_scan_url = "/system/label/scan";

export const label_scan_error = "/system/label/scanError"

//get all warehouse
export const label_warehouse_url = "/system/warehouse/all"

export const login_url = "/system/tl/user/login"

export const work_sum_url = "/system/label/sum"

export const import_original_url = '/system/excel/import/original'

export const token_check_url = '/system/tl/user/verify'

export const awb_search_url = '/system/awb/list'

export const awb_import_url = '/system/excel/import/awb'

export const awb_add_url = '/system/awb/add'

export const awb_edit_url = '/system/awb/update'

export const pack_number_check ='/system/label/query'

export const pack_number_by_bgcode = '/system/label/getLabelDetail'

export const user_list = '/system/tl/user/list'
export const add_user = '/system/tl/user/add'