import {Box, FormControl, FormLabel, Grid, IconButton, InputAdornment, useTheme} from "@mui/material";
import Header from "../../components/dashboard/Header";
import Divider from "@mui/material/Divider";
import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {tokens} from "../../theme";
import TextField from "@mui/material/TextField";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {useDispatch, useSelector} from "react-redux";
import {fetchPackNumberByBGCode, onErrorAlert} from "../../actions";
import moment from "moment";

const style = {
    page: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'centre',
        padding: 2,


    },
    container: {
        width: '100%',
        alignItems: 'center',

    },
    searchContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 2,
    },

    packContainer:{
        width: '100%',
        alignItems: 'start',
        justifyContent: 'start',
        borderRadius: 2,
    }


}
const BGCode = () => {

    const schema = yup.object().shape({})
    const {register, handleSubmit, formState: {errors}, setError, setValue} = useForm({
        resolver: yupResolver(schema),
        reValidateMode: 'onChange'
    })
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch()
    const packNumberObject = useSelector(state => state?.packNumber.packNumberByBGCode)

    const onError = (msg) => {
        dispatch(onErrorAlert(msg))
    }

    const onFetchSuccess = (data) => {
        console.log(data)
    }
    const onSubmit = async (data) => {

        let formData = {}
        formData.bgCode = data['bgCode']
        await dispatch(fetchPackNumberByBGCode(formData,onFetchSuccess,onError))
    }

    const renderForm = () =>
        <Grid item container justifyContent='center' alignItems='center' spacing={2} sx={{width: '100%'}}>
            <Grid item xs={12} >
                <FormControl fullWidth>
                    <FormLabel component='legend'>Search Fields</FormLabel>
                </FormControl>
            </Grid>
            <Grid item container xs={12} spacing={2}>
                <Grid item xs={12} sx={{display: 'flex', alignItems: 'start', justifyContent: 'center'}}>
                    <TextField
                        sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                        {...register('bgCode')}
                        variant='outlined'
                        label='BG Code' name='bgCode' fullWidth
                        error={Boolean(errors.awb?.message)}
                        color="warning"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <IconButton color={'warning'} type={'submit'}>
                                        <CheckCircleIcon/>
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}

                    />
                </Grid>
                <Grid item container xs={12} flexDirection={'column'} spacing={1}>
                    <Grid item>{packNumberObject?.packnumber?`Pack Number: ${packNumberObject?.packnumber}`:''}</Grid>
                    <Grid item>{packNumberObject?.ssCode?`SS Code: ${packNumberObject?.ssCode}`:''}</Grid>
                    <Grid item>{packNumberObject?.ssCode?`Label Time: ${moment(packNumberObject?.labeltime)}`:''}</Grid>
                </Grid>
            </Grid>
        </Grid>



    return <React.Fragment>
        <Box m="20px"
             sx={style.page}>
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Retrieve the Pack number by BGCode"/>
            </Box>
            <Box>
                <Divider variant="middle"/>
            </Box>
            <Grid
                container
                display="flex"
                flexDirection='column'
                sx={style.container}

            >
                <form onSubmit={handleSubmit(onSubmit)} style={{width: '100%'}}>
                    <Grid item container sx={style.searchContainer} p={2} backgroundColor={colors.primary[400]} xs={12} md={4}>
                        {renderForm()}
                    </Grid>
                </form>
            </Grid>
        </Box>
    </React.Fragment>
}

export default BGCode