import {noop} from "lodash";
import {label_scan_error, label_scan_url, label_staff_url, REACT_APP_API_URL} from "../../config/config";
import axios from "axios";


export const LabelActionType = {
    Fetch_Label:'Fetch_label',
    Fetch_Error:'Fetch_Error'
}


export const fetchLabel = (data,onSuccess = noop,onError = noop) => async dispatch => {

    const url = `${REACT_APP_API_URL}${label_scan_url}`

        const response = await axios.post(url,data)
            .then((res)=> res)
            .catch(e=>{
                onError('SERVER ERROR 404')
            })
        if (response?.status === 200 && response.data.code === 200 ){
            dispatch({
                type:LabelActionType.Fetch_Label,
                payload:response.data.data.parcel
            })
            onSuccess(response.data.data)

        } else {
            onError(response.data.msg)
        }


}
export const fetchError = (data,onSuccess = noop,onError = noop) => async dispatch => {

    const url = `${REACT_APP_API_URL}${label_scan_error}`

    const response = await axios.post(url,data)
        .then((res)=> res)
        .catch(e=>{
            onError('SERVER ERROR 404')
        })
    if (response?.status === 200 && response.data.code === 200 ){

        onSuccess(response.data.data)

    } else {
        onError(response.data.msg)
    }


}