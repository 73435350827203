import {AwbActionType} from "../../actions";

const initState = {
   tableRows:[]
}
export const awbReducer = (state=initState , action = {}) => {
    switch (action.type) {
        case AwbActionType.SearchAWB:
            return { ...state, tableRows: [...action.payload]}

        default:
            return state
    }
};
export default awbReducer