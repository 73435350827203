import {noop} from 'lodash';
import { label_warehouse_url, REACT_APP_API_URL} from "../../config/config";
import axios from "axios";

export const WarehouseActionType = {
    FetchAllWarehouses:'FetchAllWarehouses'
}

export const fetchAllWarehouses = (onSuccess = noop,onError = noop) => async dispatch => {
    const url = `${REACT_APP_API_URL}${label_warehouse_url}`
    try{
        const response = await axios.get(url)
            .then((res)=> res)
            .catch(e=>{

                onError('SERVER ERROR 404')
            })
        if (response?.status === 200 && response.data.code === 200 ){

            dispatch({
                type:WarehouseActionType.FetchAllWarehouses,
                payload:response.data.data
            })
            onSuccess(response.data.data)

        }
    }catch{
        onError('Server Error')
    }
}
