import {ThemeActionType} from "../../actions";

const initState = {
    mode:'dark'  // Used for customer table pagination
}
export const themeReducer = (state=initState , action = {}) => {
    switch (action.type) {
        case ThemeActionType.ToggleTheme:
            return { ...state, mode: state.mode === 'light'? 'dark':'light'}
        default:
            return state
    }
};
export default themeReducer