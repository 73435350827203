import {Navigate, Outlet} from "react-router-dom";
import LeftSideBar from "../views/global/LeftSideBar";
import {useEffect, useState} from "react";
import TopBar from "../views/global/TopBar";
import {LabelActionType, onErrorAlert, syncUser, UserActionType} from "../actions";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {login_url, REACT_APP_API_URL, token_check_url} from "../config/config";

const ProtectedRoute = ({ isRole, children}) => {
    const [isSidebar, setIsSidebar] = useState(true);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const dispatch = useDispatch()
    const auth = useSelector(state => state.user.auth)


    useEffect(()=>{

        const token = localStorage.getItem('jwt_token')
        if(token){
            const url = `${REACT_APP_API_URL}${token_check_url}`
            const response = axios.post(url,{},{
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }).then(res=> {
                if(res?.data.code === 200)
                {
                    dispatch({
                        type:UserActionType.Check_Auth,
                        payload: true
                    })

                    dispatch(syncUser(
                        {
                            userRole:Number(localStorage.getItem('user_role')),
                            userName:localStorage.getItem('user_name'),
                            whId:Number(localStorage.getItem('whId')),
                        }
                    ))


                } else {
                    dispatch({
                        type:UserActionType.Check_Auth,
                        payload: false
                    })
                    localStorage.removeItem('user_role')
                    localStorage.removeItem('user_name')
                    localStorage.removeItem('jwt_token')
                    localStorage.removeItem('whId')
                }
            })
            .catch(e =>
            dispatch(onErrorAlert(e))
            )
        }

    },[])



    if(!auth){
        dispatch(onErrorAlert('Permission Expired. Please Login again'))
        return <Navigate to='/signin'/>;
    } else if (!isRole){
        return <Navigate to='/404'/>;
    } else {
        return <>
            <LeftSideBar isSidebar={isSidebar} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed}/>
            <main className='content' style={isCollapsed?{ marginLeft: '100px',padding: '20px',maxWidth:'90vw'}:{marginLeft: '240px',padding: '20px',maxWidth:'85vw'}}>
                <TopBar setIsSidebar={setIsSidebar} />
                {children ? children : <Outlet />}
            </main>
        </>
    }
}

export default ProtectedRoute