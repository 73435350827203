import React, {useEffect, useState} from 'react'
import Draggable from "react-draggable";
import Paper from "@mui/material/Paper";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl, FormLabel, IconButton, InputAdornment,
    InputLabel, OutlinedInput, Radio, RadioGroup
} from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {userRoles} from "../../config/userRoleMapping";
import {Email, Visibility, VisibilityOff} from "@mui/icons-material";
import FormControlLabel from "@mui/material/FormControlLabel";
import * as Yup from "yup";
import Typography from "@mui/material/Typography";
import {addAWB, addUser, onErrorAlert, onSuccessAlert, searchAWB, searchUsers} from "../../actions";
import {noop} from "lodash";
const UserEditor = ({openModal,handleClose,search,selectWarehouse,setSelectWarehouse}) => {

    const schema = yup.object().shape({
        password: yup.string().required('Password is required'),
        passwordConfirmation: yup.string()
            .oneOf([yup.ref('password'), null], 'Passwords must match')
    })
    const loginRole = localStorage.getItem('user_role')
    const loginWhId = localStorage.getItem('whId')
    const allWarehouse = useSelector(state => state?.warehouse.allWarehouse)
    const [userRole, setUserRole] = useState(null)
    const [showPassword, setShowPassword] = React.useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = React.useState(false);
    const [gender,setGender] = useState('O')
    const dispatch = useDispatch()
    const [disableWh,setDisableWh] = useState(false)
    const [disableRole,setDisableRole] = useState(false)
    const {register,reset,resetField,handleSubmit, formState: {errors}, setError, setValue} = useForm({
        resolver: yupResolver(schema),
        reValidateMode: 'onChange'
    })

    useEffect(() => {
        setValue('password','111111')
        setValue('passwordConfirmation','111111')
        if(loginWhId && loginWhId!=='null'&& loginRole === '4'){
            setSelectWarehouse(allWarehouse.find(item=>item.whId === Number(loginWhId)))
            setDisableWh(true)
            setUserRole(userRolesArray.find(item => item.id === 2))
            setDisableRole(true)
        }
    }, []);
    function PaperComponent(props) {
        return (
            <Draggable
                handle="#draggable-dialog-title"
                cancel={'[class*="MuiDialogContent-root"]'}
            >
                <Paper sx={{width:'50vw'}} {...props} />
            </Draggable>
        );
    }

    function onError(msg) {
        dispatch(onErrorAlert(msg))
    }

    function onReFetchSuccess() {

    }

    function onAddUserSuccess(msg) {
        dispatch(onSuccessAlert(msg))
        handleCloseAndClear()
        dispatch(searchUsers(search,onReFetchSuccess = noop,onError = noop))

    }

    const onSubmit = (data) => {

        let formData = {}
        formData.firstName = data.firstName
        formData.lastName = data.lastName
        formData.pwd = data.password
        formData.roleId = userRole.roleId
        if(selectWarehouse?.whId){
            formData.whId = selectWarehouse?.whId
            if(userRole.roleId === 1 && selectWarehouse){
                dispatch(onErrorAlert('selecting a warehouse is not required when use role is admin,Please try again'))
                setSelectWarehouse(null)
            }
        }
        formData.email = data.email
        formData.mobile = data.mobile
        formData.gender = gender

        const axiosPostAdd = async () => {
            await dispatch(addUser(formData,onAddUserSuccess ,onError))
        }

        axiosPostAdd().then()
    }

    function handleClickShowPassword() {
        setShowPassword( showPassword => !showPassword)
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    function handleClickShowPasswordConfirm() {
        setShowPasswordConfirm( showPasswordConfirm => !showPasswordConfirm)
    }

    const handleMouseDownPasswordConfirm = (event) => {
        event.preventDefault();
    };
    const handleCloseAndClear = () => {
        setValue('firstName',null)
        setValue('lastName',null)
        setValue('mobile',null)
        setValue('email',null)
        setValue('password','111111')
        setValue('passwordConfirmation','111111')
        setShowPassword(false)
        setShowPasswordConfirm(false)
        handleClose()
    }

    const renderModal = () =>
        <Dialog
            open={openModal}
            onClose={handleCloseAndClear}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle style={{ cursor: 'move', background:'#ff9800',fontWeight:'bold'}} >
                {'Add'}
            </DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)} style={{width: '100%'}}>
                <DialogContent >
                    <Grid item container xs={12} mt={1} spacing={2}>
                        <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'start', justifyContent: 'center'}}>
                            <TextField
                                required
                                sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                                {...register('firstName')}
                                variant='outlined'
                                label='First Name' name='firstName' fullWidth
                                error={Boolean(errors.firstName?.message)}
                                helperText={errors.firstName?.message}
                                color="warning"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'start', justifyContent: 'center'}}>
                            <TextField
                                required
                                sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                                {...register('lastName')}
                                variant='outlined'
                                label='Last Name' name='lastName' fullWidth
                                error={Boolean(errors.lastName?.message)}
                                helperText={errors.lastName?.message}
                                color="warning"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}  sx={{display: 'flex', alignItems: 'start', justifyContent: 'center'}}>

                            <FormControl fullWidth color='warning' >
                                <Autocomplete
                                    disablePortal
                                    disabled={disableWh}
                                    options={allWarehouse}
                                    getOptionLabel={(option) => `${option?.whId}. ${option?.whName}`}
                                    isOptionEqualToValue={(option, value) => option?.whId === value?.whId}
                                    value={selectWarehouse}
                                    onChange={(e, v) => setSelectWarehouse(v)}
                                    renderInput={(params) =>
                                        <TextField
                                            color='warning' {...params}
                                            label="Warehouse" />}
                                />
                            </FormControl>
                        </Grid>


                        <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'start', justifyContent: 'center'}}>
                            <FormControl fullWidth color='warning' >
                                <Autocomplete
                                    required
                                    disablePortal
                                    disabled={disableRole}
                                    options={userRolesArray}
                                    getOptionLabel={(option) => `${option?.role}`}
                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                    value={userRole}
                                    onChange={(e, v) => setUserRole(v)}
                                    renderInput={(params) =>
                                        <TextField
                                            required={true}
                                            color='warning' {...params}
                                            label="User Role"

                                        />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'start', justifyContent: 'center'}}>
                            <FormControl  variant="outlined" fullWidth color='warning'>
                                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                <OutlinedInput
                                    type={showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                    {...register('password')}
                                    name={'password'}
                                    error={Boolean(errors?.password?.message)}

                                />
                                <Typography color={'error'}>{errors?.password?.message}</Typography>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'start', justifyContent: 'center'}}>
                            <FormControl  variant="outlined" fullWidth color='warning'>
                                <InputLabel htmlFor="outlined-adornment-password">Password Confirm</InputLabel>
                                <OutlinedInput
                                    type={showPasswordConfirm ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPasswordConfirm}
                                                onMouseDown={handleMouseDownPasswordConfirm}
                                                edge="end"
                                            >
                                                {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password Confirm"
                                    {...register('passwordConfirmation')}
                                    name={'passwordConfirmation'}
                                    error={Boolean(errors?.passwordConfirmation?.message)}
                                />
                                <Typography color={'error'}>{errors?.passwordConfirmation?.message}</Typography>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'start', justifyContent: 'center'}}>
                            <TextField {...register('email')}
                                       color={'warning'}
                                       type={'email'}
                                       variant='outlined' margin='normal' fullWidth
                                       label='Email' name='email' autoComplete='email'
                                       error={Boolean(errors.email?.message)}
                                       helperText={errors.email?.message}
                                       InputProps={{
                                           startAdornment: (
                                               <InputAdornment position='start'>
                                                   <Email />
                                               </InputAdornment>
                                           ),
                                       }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'start', justifyContent: 'center'}}>
                            <TextField {...register('mobile')}
                                       color={'warning'}
                                       variant='outlined' margin='normal' fullWidth
                                       label='Cellphone' name='mobile'
                                       error={Boolean(errors?.mobile?.message)}
                                       helperText={errors?.mobile?.message}
                                       InputProps={{
                                           startAdornment: (
                                               <InputAdornment position='start'>
                                                   <LocalPhoneIcon/>
                                               </InputAdornment>
                                           ),
                                       }}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} sx={{display:'flex', alignItems:'center', justifyContent:'start'}}>
                            <FormLabel sx={{marginRight:1}}>Gender</FormLabel>
                            <FormControl fullWidth color={'warning'} >
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="O"
                                    name="radio-buttons-group"
                                    value={gender}
                                    onChange={(e) => setGender(e.target.value)}
                                >
                                    <FormControlLabel value="F" control={<Radio color="warning"/>} label="Female"/>
                                    <FormControlLabel value="M" control={<Radio color={'warning'}/>} label="Male" />
                                    <FormControlLabel value="O" control={<Radio color={'warning'}/>} label="Other" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                    </Grid>

                </DialogContent>

                <DialogActions>
                    <Button type='submit'  variant='outlined' color='warning'>
                        {'Add'}
                    </Button>
                    <Button variant='outlined' color='warning' onClick={handleCloseAndClear}> cancel</Button>
                </DialogActions>
            </form>
        </Dialog>

    const userRolesArray = Object.entries(userRoles).map(([role, roleId]) => ({
        id: roleId,
        role: role,
        roleId: roleId
    }));

    return (
        <React.Fragment>
            {renderModal()}
        </React.Fragment>
    )
}

export default UserEditor