import {LabelActionType} from "../../actions";

const initState = {
    parcel:{
        name:'',
        address1:'',
        address2:'',
        city:'',
        stateProvince:'',
        postalCode:'',
        country:'',
        contractPhone:'',
        airport:'',
        sku:'',
        orderNumber:'',
        packageDescription:'',
        weightUom:'',
        weight:'',
        length:'',
        width:'',
        height:'',
        dimensionsUom:'',
        ssCode:''


    }
}
export const labelReducer = (state=initState , action = {}) => {
    switch (action.type) {
        case LabelActionType.Fetch_Label:
              return { ...state, parcel: action.payload}

        default:
            return state
    }
};
export default labelReducer