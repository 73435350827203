import {combineReducers} from "redux";
import {themeReducer as theme} from './theme/themeReducer';
import {userReducer as user} from './auth/userReducer'
import {messageReducer as message} from './message/messageReducer'
import {warehouseReducer as warehouse} from './warehouse/warehouseReducer'
import {labelReducer as label} from './label/labelReducer'
import {awbReducer as awb } from './awb/awbReducer'
import {packNumberReducer as packNumber} from './report/packNumberReducer'

const reducer = combineReducers({
    theme,
    user,
    message,
    warehouse,
    label,
    awb,
    packNumber
})
export default reducer