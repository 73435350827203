import {UserActionType} from "../../actions";


const initState = {
    auth:true,
    userId:null,
    userName:null,
    userRole:1,
    allStaff:[],
    token:'',
    whId:null,
    userList:[],
    totalRows:0
}
export const userReducer = (state=initState , action = {}) => {
    switch (action.type) {
        case UserActionType.Login_Success:
            return { ...state,
                userId:action.payload.uid,
                userName: action.payload.name,
                userRole:action.payload.roleId,
                whId: action.payload.whId,
                auth:true}
        case UserActionType.Fetch_All_Staff:
            return {...state, allStaff: action.payload}
        case UserActionType.Check_Auth:
            return {...state, auth: action.payload}

        case UserActionType.Sync_UserRole:
            return {...state, userRole: action.payload}
        case UserActionType.Sync_User:
            return {...state,userRole: action.payload?.userRole,userName: action.payload?.userName,whId: action.payload?.whId}
        case UserActionType.Logout:
            return {...initState}
        case UserActionType.Fetch_User_List:

            return {...state,userList: action.payload?.list,totalRows: action.payload?.total}

        default:
            return state
    }
};
export default userReducer