import {MessageActionType} from "../../actions";
const initState = {
    status:'',
    message:''
}
export const messageReducer = (state=initState , action = {}) => {
    switch (action.type) {
        case MessageActionType.Success:
            return { ...state, status:'success', message:action.payload}
        case MessageActionType.Error:
            return { ...state, status:'error', message:action.payload}

        case MessageActionType.Clear:
            return { ...state, status:'', message:''}

        default:
            return state
    }
};
export default messageReducer