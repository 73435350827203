import { Snackbar, Button, Alert, AlertProps } from '@mui/material'
import {useState, forwardRef, useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {onClearAlert} from "../../actions";
import {AnimatePresence, motion} from 'framer-motion'

const SnackbarAlert = forwardRef(
    function SnackbarAlert(props, ref) {
        return <Alert elevation={3}  ref={ref} {...props} />
    }
)

 const MuiSnackbar = () => {
     const status = useSelector( state => state.message.status)
     const message = useSelector( state => state.message.message)
     const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const handleClose = (event,reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpen(false)
        dispatch(onClearAlert())
    }

     useEffect(() => {
         if (status === 'success' || status === 'error'){
             setOpen(true)
         }

     }, [status]);


    return (
        <AnimatePresence>
            <motion.div >
                {/*<Button onClick={() => setOpen(true)}>Submit</Button>*/}
                <Snackbar open={open} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
                    <SnackbarAlert variant="filled"  onClose={handleClose} severity={status}>
                        {message}
                    </SnackbarAlert>
                </Snackbar>
            </motion.div>
            </AnimatePresence>
    )
}

export default MuiSnackbar