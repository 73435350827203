import { useState } from "react";
import {Sidebar, Menu, MenuItem, SubMenu} from "react-pro-sidebar";
import { Link } from 'react-router-dom';
import {Avatar, Box, IconButton, Typography, useTheme} from "@mui/material";
import { tokens } from "../../theme";
import {useSelector} from "react-redux";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import AssessmentIcon from '@mui/icons-material/Assessment';
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import WidgetsIcon from '@mui/icons-material/Widgets';
import InventoryIcon from '@mui/icons-material/Inventory';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {pageAuth, userRoles} from "../../config/userRoleMapping";
import ErrorIcon from '@mui/icons-material/Error';

const LeftSideBar = ({isCollapsed, setIsCollapsed}) => {
    const theme = useTheme();
    const mode = useSelector((state) => state.theme.mode);
    const colors = tokens(theme.palette.mode);
    const userRole = Number(localStorage.getItem('user_role'))
    const userName = useSelector(state => state.user.userName)
    const [selected, setSelected] = useState("Dashboard");

    const Item = ({ title, to, icon, selected, setSelected,children=[]}) => {

        const theme = useTheme();
        const colors = tokens(theme.palette.mode);
        return (
            children.length === 0 ?
                <MenuItem
                    active={selected === title}
                    style={{
                        color: colors.grey[100],
                        paddingLeft:'20px'
                    }}
                    onClick={() => setSelected(title)}
                    icon={icon}
                    component={<Link to={to}/>}
                >
                    <Typography>{title}</Typography>

                </MenuItem>:
                <SubMenu
                    label={title}
                    icon={icon}
                    rootStyles={

                        mode === 'dark'?{

                            button: {
                                [`&:hover`]: {
                                    backgroundColor: '#141b2d',
                                },
                            },
                        }:{
                            button: {
                                [`&:hover`]: {
                                    backgroundColor: '#c2c2c2',
                                },
                            },
                        }}
                >
                    {children.map((child,index) => (
                        <MenuItem
                            key={index}
                            active={selected === child}
                            rootStyles={

                                mode === 'dark'?{

                                    button: {
                                        [`&:hover`]: {
                                            backgroundColor: '#141b2d',
                                        },
                                    },
                                    color: colors.grey[100],
                                    backgroundColor: colors.primary[400]
                                }:{
                                    button: {
                                        [`&:hover`]: {
                                            backgroundColor: '#c2c2c2',
                                        },
                                    },
                                    color: colors.grey[100],
                                    backgroundColor: colors.primary[400]
                                }}
                            onClick={() => setSelected(title)}
                            component={<Link to={child.to}/>}
                        >
                            <Typography>{child.title}</Typography>
                        </MenuItem>
                    ))}
                </SubMenu>
        );
    };


    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                bottom: 0,
                width: '20vw',
                zIndex:99,
                height: '100%',
                "& .ps-sidebar-root":{
                    height:'100%',
                    backgroundColor: colors.primary[400]
                },
                "& .ps-sidebar-container":{
                    height:'100vh',
                    backgroundColor: colors.primary[400]

                }
            }}
        >


            <Sidebar collapsed={isCollapsed} style={{border:'none'}}>

                <Menu iconShape="square"

                      menuItemStyles={

                        mode === 'dark'?{

                          button: {
                              [`&:hover`]: {
                                  backgroundColor: '#141b2d',
                              },
                          },
                        }:{
                            button: {
                                [`&:hover`]: {
                                    backgroundColor: '#c2c2c2',
                                },
                            },
                        }}
                >
                    {/* LOGO AND MENU ICON */}
                    <MenuItem
                        onClick={() => setIsCollapsed(!isCollapsed)}
                        icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
                        style={{
                            margin: "10px 0 20px 0",
                            color: colors.grey[100],

                        }}
                    >
                        {!isCollapsed && (
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                ml="15px"
                            >
                                <Typography variant="h3" color={colors.grey[100]}>
                                    Darwynn Ltd.
                                </Typography>
                                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                                    <MenuOutlinedIcon />
                                </IconButton>
                            </Box>
                        )}
                    </MenuItem>

                    {!isCollapsed && (
                        <Box mb="25px">
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <Avatar>H</Avatar>
                            </Box>
                            <Box textAlign="center">
                                <Typography
                                    variant="h2"
                                    color={colors.grey[200]}
                                    fontWeight="bold"
                                    sx={{ m: "10px 0 0 0" }}
                                >
                                    {userName}
                                </Typography>
                                <Typography variant="h5" color='#ed6c02'>
                                    {
                                        Object.entries(userRoles).find(([_, value]) => value === userRole)[0]
                                    }
                                </Typography>
                            </Box>
                        </Box>
                    )}

                    <Box  >
                        <Item
                            title="Home"
                            to="/dashboard"
                            icon={<HomeOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />
                        {userRole !== 2 && <Item
                            title="Import"
                            to="/import"
                            icon={<FileUploadIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />}
                        {pageAuth.AWB.includes(userRole) &&<Item
                            title="AWB"
                            to="/awb"
                            icon={<WidgetsIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />}
                        {userRole === 1 && <Item
                            title="Sign For"
                            to="/signing"
                            icon={<ReceiptOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />}
                        {userRole !== 3 && <Item
                            title="Label"
                            to="/label"
                            icon={<LocalPrintshopIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />}
                        {userRole === 1 && <Item
                            title="Pack"
                            to="/pack"
                            icon={<InventoryIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />}
                        {pageAuth.REPORT.includes(userRole) && <Item
                            title="Report"
                            to="/report"
                            icon={<AssessmentIcon />}
                            selected={selected}
                            setSelected={setSelected}
                            children={[{title:'Pack Number',to:'/report/packnumber'},{title:'BG Code',to:'/report/bgcode'}]}
                        />}
                        {userRole === 1 && <Item
                            title="Warehouse"
                            to="/warehouse"
                            icon={<WarehouseIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />}
                        {pageAuth.USER.includes(userRole) && <Item
                            title="User"
                            to="/user"
                            icon={<PeopleOutlinedIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />}
                        {pageAuth.ERROR.includes(userRole) && <Item
                            title="Error"
                            to="/error"
                            icon={<ErrorIcon />}
                            selected={selected}
                            setSelected={setSelected}
                        />}


                    </Box>
                </Menu>
            </Sidebar>
        </Box>
    )
}

export default LeftSideBar;