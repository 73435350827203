import {WarehouseActionType} from "../../actions";

const initstate = {
    allWarehouse: [],
    currentWarehouse: null// Used for customer table pagination
}
export const warehouseReducer = (state = initstate , action = {}) => {
    switch (action.type) {
        case WarehouseActionType.FetchAllWarehouses:

            return { ...state, allWarehouse: [...action.payload]}
        default:
            return state
    }
};
export default warehouseReducer