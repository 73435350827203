import React,{useEffect, useState} from "react";
import {
    Button,
    CardMedia,
    Dialog, DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    Stack,
    Tooltip,
    Typography,
    useTheme
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import axios from "axios";
import {FileIcon} from "react-file-icon"
import ClearIcon from "@mui/icons-material/Clear";
import moment from "moment";
import { noop } from 'lodash';
import {tokens} from "../../theme";
import Draggable from "react-draggable";
import Paper from "@mui/material/Paper";

const actionType = {
    UPLOAD:'upload',
    DELETE:'delete'
}


export const fileTypes = {
    // PNG:'png',
    // JPG:'jpg',
    // JPEG:'jpeg',
    // DOC:'doc',
    // DOCX:'docx',
    // PDF:'pdf',
    // TXT:'txt',
    // RAR:'rar',
    // ZIP:'zip',
    excel:'xlsx',
    // svg:'svg',
    // ppt:'ppt',
    // pptx:'pptx',
    // gif:'gif',

}

export function FileUploader({acceptDefaultTypes= true, baseFileURL='' ,onSuccess = noop,onError = noop,fileType,maxKB=(5*1024),list,color,asFormInputComponent= true,handleImageCallBack = noop,multipleFile = true , register}) {

    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    function PaperComponent(props) {
        return (
            <Draggable
                handle="#draggable-dialog-title"
                cancel={'[class*="MuiDialogContent-root"]'}
            >
                <Paper {...props} />
            </Draggable>
        );
    }
    const useStyles = {
        container: {
            margin: theme.spacing(3),
            backgroundColor: colors.primary[400],
            padding:'1rem',
            width:'500px',


        },
        uploaderContainer:{
            padding:'3rem',
            border:`2px dashed ${colors.primary[100]}`,
            textAlign:'center',

        },

        displayFiles:{
            marginTop:'1rem'
        },

        fileGrid:{
            border:`1px dashed ${colors.primary[100]}`,
            padding:'1rem',
            marginBottom:'5px',
            maxHeight:'6rem',
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            textAlign:'center',
            height:'200px'

        },
        mediaGrid:{
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            padding:'5px',

        },

    }

    const [fileList,setFileList] = useState([])
    const [uploaded,setUploaded] = useState(null)
    const [progress,setProgress] = useState(0)
    const [supportedType,setSupportedType] = useState([])
    const [previewUrl,setPreviewUrl] = useState('')
    const [modelFile,setModelFile] = useState({})
    const [modelOpen,setModelOpen] = useState(false)
    const [resourceCancelToken,setResourceCancelToken] = useState(axios.CancelToken.source())


    useEffect(()=>{

        if(list.length!==0){
            setFileList(()=>[...list])
        } else if(!asFormInputComponent){
            setFileList(()=>fileList.filter(v=>v.beforeUploaded === false))
        }
    },[list])

    useEffect(()=>{
        let tempArr = []
        if(acceptDefaultTypes){
            // for (const k in fileTypes) {
            //   tempArr = [...tempArr,fileTypes[k]]
            // }
            Object.keys(fileTypes).forEach( v => {tempArr = [...tempArr,fileTypes[v]]; return null})

            setSupportedType([...tempArr])
        }else{

            setSupportedType([...fileType.split(',')])
        }

    },[])

    useEffect(()=>{
        if(uploaded){
            fileList[0] = uploaded
            setProgress(0)
        }
    },[uploaded])

    useEffect(()=>{
        handleImageCallBack(fileList.map(({file})=>file))
        setProgress(0)
        setUploaded(null)
    },[fileList])


    function convertAcceptTypeToString (typeArr,char) {
        let typeStr = ''
        typeArr.forEach((v,i)=> {
                if (i===0) {
                    typeStr += v
                }
                else {
                    typeStr = typeStr + char + v}
            }
        )
        return typeStr
    }

    const convertToInputAccept = typeArr => {
        let typeStr = ''
        typeArr.forEach(v => {typeStr = `${typeStr}.${ v },`})
        return typeStr
    }

    const uploadConfig = {
        onUploadProgress: (progressEvent) => {
            const progressNum = Math.trunc((progressEvent.loaded / progressEvent.total)*100);
            setProgress(progressNum)
        },
        cancelToken:resourceCancelToken?.token
    }


    const uploadHandler = async event => {

        const file = event?.target?.files[0]
        if(file === undefined){
            return {}
        }
        const name = event?.target?.files[0]?.name
        const title = name.substring(0, name.indexOf("."))
        if(file.size > (1024 * maxKB)){
            onError(`file cannot over ${maxKB}KB`)
            return {}
        }


        // create new formData
        const formData = new FormData()
        formData.append('title', title)
        formData.append('filename', name)
        formData.append('file', file)
        setPreviewUrl(URL.createObjectURL(file))


        // upload new file
        let currentUploading
        if(asFormInputComponent){
            currentUploading = {'title':title,'filename':name,'file':file,'isUploading':false,'url':URL.createObjectURL(file)}
            if (multipleFile) {
                setFileList(()=>[currentUploading,...fileList])
            }
            else {setFileList(()=>[...[],currentUploading])}
        } else{
            if (multipleFile) {setFileList(()=>[currentUploading,...fileList])}
            else {setFileList(()=>[...[],currentUploading])}
            currentUploading = {'title':title,'filename':name,'file':file,'isUploading':true}
            try {
                const res = await axios.post(baseFileURL, formData, uploadConfig)

                if (res.data?.rs) {
                    setUploaded({...res?.data?.data,'beforeUploaded':false,'isUploading':false,'url':URL.createObjectURL(file)})
                    setProgress(0)
                    return onSuccess(res.data?.message,actionType.UPLOAD,res?.data?.data)
                }
                return onError(res.data?.message || 'Error uploading resource file',actionType.UPLOAD)
            } catch (e) {
                if(axios.isCancel(e)){
                    return onSuccess('upload request cancel', actionType.UPLOAD)
                }
                return onError('Error in uploading file',actionType.UPLOAD)

            }
        }
        return false
    }

    const deleteResource = (file, uploading, onSuccessReturn, onErrors) => async () => {
        if(asFormInputComponent){
            setFileList( fileList.filter(v=> v!== file))
            return {}
        }
        if(uploading){
            resourceCancelToken.cancel()
            setResourceCancelToken(axios.CancelToken.source())
            fileList.shift()
        }else{
            try {
                const url = `${baseFileURL  }/${ file?.id}`
                const res = await axios.delete(url)
                if (res.data?.rs) {
                    setFileList( fileList.filter(v=> v.id !== file?.id))
                    setProgress(0)
                    return onSuccessReturn(res.data?.message ||'Resource is deleted successfully', actionType.DELETE,{id:file?.id, filename:file?.filename})
                }
                return onErrors(res.data?.message || 'Error in deleting resource',actionType.DELETE)
            } catch (e) {
                onErrors('Error in deleting resource',actionType.DELETE,{id:file?.id, filename:file?.filename})
            }
        }
        return false
    }


    const downloadFile = file => {
        const link = document.createElement('a')
        link.setAttribute('target',"_blank")
        link.href = file?.url
        link.download = file?.filename
        document.body.appendChild(link)
        link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: false, view: window}));
        link.parentNode.removeChild(link);
    }

    const fileGrid = (file, progressValue, isUploading = false) => {


        const ext = file?.filename?.split('.').pop()
        const image  = ['png','jpg','jpeg','svg','gif',]
        const showingImage = image.includes(file?.filename?.split('.').pop())
        const previewObject = ['pdf']
        const showingObject = previewObject.includes(file?.filename?.split('.').pop())

        return(
            <Grid sx={useStyles.fileGrid} container>
                <Grid sx={useStyles.mediaGrid} item xs={2} container >
                    {
                        showingImage
                            ?
                            <CardMedia
                                component="img"
                                image={isUploading?previewUrl:file.url}
                                alt={file?.filename}
                                onClick={()=>{
                                    setModelOpen(true)
                                    setModelFile(isUploading?{...file,'url':previewUrl}:file)
                                }}/>
                            :(
                                showingObject?
                                    <div aria-hidden="true"
                                         role="button"
                                         tabIndex={0}
                                         onClick={
                                             ()=>{
                                                 setModelOpen(true)
                                                 setModelFile(isUploading?{...file,'url':previewUrl}:file)}}
                                         style={useStyles.object}
                                    >
                                        <object width='50' height='50' aria-hidden="true"
                                                style={{pointerEvents:'none'}}
                                                data={isUploading?previewUrl:file?.url}
                                                aria-label="previewUrlToObject"
                                        />

                                    </div>
                                    :
                                    <div style={{width:'50px',height:'100%',padding:'0px'}}
                                         onClick={
                                             ()=>{
                                                 setModelOpen(true)
                                                 setModelFile(isUploading?{...file,'url':previewUrl}:file)}

                                    }
                                    ><FileIcon extension={ext} color='#ff9800'/></div>




                            )
                    }


                </Grid>
                <Grid item container xs={9} justifyContent='space-between' alignItems='center' sx={{textAlign:'start',height:'100%',padding:'5px'}}>
                    <Grid item container xs={10} flexDirection='column'>
                        <Grid item xs={12} onClick={()=>downloadFile(file)} sx={{cursor:'pointer'}}>
                            {file?.filename}
                        </Grid>
                    </Grid>
                    {/*{!asFormInputComponent && <Grid item xs={2}>*/}
                    {/*    <CircularProgressWithLabel value={isUploading ? progressValue:100} />*/}
                    {/*</Grid>}*/}
                </Grid>
                <Grid item xs={1}>
                    <Tooltip title="Delete">
                        <IconButton color='warning' onClick={
                            deleteResource(file,isUploading, onSuccess, onError)
                        }>
                            <ClearIcon/>
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        )

    }


    return (
        <Stack sx={useStyles.container} >
            <Grid container >
                <Grid item container  xs = {12} sx={useStyles.uploaderContainer} >
                    <Grid xs = {12} item sx={useStyles.button}>
                        <IconButton  color="warning" aria-label="upload picture" component="label" >
                            <input hidden accept={convertToInputAccept(supportedType)} type="file" onChange={uploadHandler} ref={register}/>
                            <CloudUploadIcon fontSize="large"/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} >
                        <Typography  color="warning" xs = {12}> Max {maxKB}kb</Typography>
                    </Grid>
                    <Grid item xs={12} >
                        <Typography  color="warning" xs = {12}>Supported Types: {convertAcceptTypeToString(supportedType,', ')}</Typography>
                    </Grid>

                </Grid>

            </Grid>
            <Grid item xs = {12} sx={useStyles.displayFiles}>
                {fileList.map((value)=><div key={value}>
                    {fileGrid(value,progress,value?.isUploading)}
                </div>)}
            </Grid>
            {/*<DraggableDialog open={modelOpen}*/}
            {/*                 onClose={()=>setModelOpen(false)}*/}
            {/*                 title={modelFile?.filename}>*/}
            {/*    <div style={{width:'100%',height:'100%'}}>*/}
            {/*        <object data={modelFile?.url} width='100%' height='100%' style={{objectFit:'fill'}} aria-labelledby="objectUrl"/>*/}
            {/*    </div>*/}


            {/*</DraggableDialog>*/}


            <Dialog
                open={modelOpen}
                onClose={()=>setModelOpen(false)}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    {modelFile?.filename}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <div style={{width:'100%',height:'100%'}}>
                            <object data={modelFile?.url} width='100%' height='100%' style={{objectFit:'fill'}} aria-labelledby="objectUrl"/>
                        </div>
                    </DialogContentText>
                </DialogContent>
                {/*<DialogActions>*/}
                {/*    <Button autoFocus onClick={handleClose}>*/}
                {/*        Cancel*/}
                {/*    </Button>*/}
                {/*    <Button onClick={handleClose}>Subscribe</Button>*/}
                {/*</DialogActions>*/}
            </Dialog>





        </Stack>)
}
