import React from 'react';
import {Link} from 'react-router-dom';
import {
    Box,
    Button,
    Container,
    Typography,
    useTheme,
    useMediaQuery,
} from '@mui/material';

import Page from '../../components/public/Page';



function NotFoundView() {

    const theme = useTheme()
    const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'))

    const classes = {
        root: {

            minHeight: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent:'center',
            padding: theme.spacing(3),
            paddingTop: 80,
            paddingBottom: 80,
            paddingLeft:'10%',
            paddingRight:'10%'

        },
        image: {
            maxWidth: '100%',
            width: 560,
            maxHeight: 300,
            height: 'auto'
        }
    }




    return (
        <Page
            style={classes.root}
            title="404: Not found"
        >
            <Container maxWidth="lg">
                <Typography
                    align="center"
                    variant={mobileDevice ? 'h4' : 'h1'}
                    color="textPrimary"
                >
                    404: The page you are looking for isn’t here
                </Typography>
                <Typography
                    align="center"
                    variant="subtitle2"
                    color="textSecondary"
                >
                    You either tried some shady route or you
                    came here by mistake. Whichever it is, try using the navigation.
                </Typography>
                <Box
                    mt={6}
                    display="flex"
                    justifyContent="center"
                >
                    <img
                        alt="Under development"
                        style={classes.image}
                        src="/static/images/404_error.svg"
                    />
                </Box>
                <Box
                    mt={6}
                    display="flex"
                    justifyContent="center"
                >
                    <Button
                        color="secondary"
                        component={Link}
                        to="/dashboard"
                        variant="outlined"
                    >
                        Back to home
                    </Button>
                </Box>
            </Container>
        </Page>
    );
}

export default NotFoundView;
