import {awb_add_url, awb_edit_url, awb_search_url, REACT_APP_API_URL} from "../../config/config";
import axios from "axios";
import {LabelActionType} from "../label/labelAction";
import {noop} from "lodash";

export const AwbActionType = {
    SearchAWB:'SearchAWB'
}

export const searchAWB = (data,onSuccess=noop,onError=noop) => async dispatch => {
    const url = `${REACT_APP_API_URL}${awb_search_url}`

    const response = await axios.post(url, data)
        .then((res) => res)
        .catch(e => {
            onError('SERVER ERROR 404')
        })

    if (response?.status === 200 && response.data.code === 200) {
        dispatch({
            type: AwbActionType.SearchAWB,
            payload: response.data.rows
        })
        onSuccess(response.data.data)

    } else {
        onError(response.data.msg)
    }
}

export const addAWB = (data,onSuccess=noop,onError=noop) => async dispatch => {
    const url = `${REACT_APP_API_URL}${awb_add_url}`

    const response = await axios.post(url, data)
        .then((res) => res)
        .catch(e => {

            onError('SERVER ERROR 404')
        })

    if (response?.status === 200 && response.data.code === 200) {
        onSuccess('Create an AWB Successfully')
    } else {
        onError(response.data.msg | 'Server Error')
    }


}

export const editAWB = (data,onSuccess=noop,onError=noop) => async dispatch => {
    const url = `${REACT_APP_API_URL}${awb_edit_url}`
    const response = await axios.post(url, data)
        .then((res) => res)
        .catch(e => {

            onError('SERVER ERROR 404')
        })

    if (response?.status === 200 && response.data.code === 200) {
        onSuccess('Edit AWB Successfully')
    } else {
        onError(response.data.msg | 'Server Error')
    }
}
