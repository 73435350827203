import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {applyMiddleware, createStore} from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import {Provider as ReduxProvider} from 'react-redux';
import {thunk} from "redux-thunk";
import reducer from './reducers';
import {BrowserRouter} from "react-router-dom";

const reduxStore = createStore(
    reducer, composeWithDevTools(
            applyMiddleware(thunk),
        )

)



ReactDOM.createRoot(document.getElementById('root')).render(

        <ReduxProvider store={reduxStore}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </ReduxProvider>

);


