import {
    Autocomplete,
    Box,
    Button, Dialog, DialogActions, DialogContent, DialogTitle,
    FormControl,
    FormLabel,
    InputLabel, Modal,
    Select,
    useTheme
} from "@mui/material";
import {tokens} from "../../theme";
import Header from "../../components/dashboard/Header";
import Divider from '@mui/material/Divider';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import {renderTimeViewClock} from "@mui/x-date-pickers";
import MenuItem from '@mui/material/MenuItem';
import React, {useEffect, useState} from "react";
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment'
import AwbEditor from "./AwbEditor";
import {fetchAllWarehouses, onErrorAlert, onSuccessAlert, searchAWB} from "../../actions";
import {useDispatch, useSelector} from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Draggable from "react-draggable";
import Paper from "@mui/material/Paper";
import {FileUploader} from "../../components/fileUploader/FIleUploader";
import {awb_import_url, import_original_url, REACT_APP_API_URL} from "../../config/config";
import axios from "axios";


const AWB = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [selectWarehouse, setSelectWarehouse] = useState(null)
    const [editData,setEditData] = useState(null)
    const [startDate,setStartDate] = useState(null)
    const [endDate,setEndDate] = useState(null)
    const [pageSize,setPageSize] = useState(10)
    const [pageNum,setPageNum] = useState(1)
    const [openModal,setOpenModal] = useState(false)
    const [isEdit,setIsEdit] = useState(false)
    const [warehouseLoading, setWarehouseLoading] = useState(false)
    const dispatch = useDispatch()
    const allWarehouse = useSelector(state => state?.warehouse.allWarehouse)
    const [stableLoading,setTableLoading] = useState(false)
    const [importOpen,setImportOpen] = useState(false)
    const [uploadFile,setUploadFile] = useState([])
    const [importloading,setImportLoading] = useState(false)
    const tablerows = useSelector(state => state?.awb?.tableRows)
    const [search,setSearch] = useState({})

    let modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '600px',
        bgcolor: colors.primary[600],
        boxShadow: 24,
        p: 4,
    }

    function onWarehouseSuccess() {
        setWarehouseLoading(false)
    }

    function onError(msg) {
        dispatch(onErrorAlert(msg))
    }

    useEffect(() => {
        const axiosPostAllWhsAndAWS = async () => {

            setWarehouseLoading(true)
            await dispatch(fetchAllWarehouses(onWarehouseSuccess, onError))
            setTableLoading(true)
            await dispatch(searchAWB({pageNum,pageSize},onSearchSuccess,onError))
        }
        axiosPostAllWhsAndAWS().then()

    }, [])




    const columns = [
        {
            field: 'awbCode', headerName: 'AWB', width: 170
        },
        {
            field: 'warehouse',
            headerName: 'Warehouse',
            width: 190,
            renderCell: (params) => {

                return<>{params?.value?.whName}</>
            }

        },
        {
            field: 'eta',
            headerName: 'ETA',
            width: 160,
            valueFormatter: params =>
                moment(params?.value).format("YYYY-MM-DD hh:mm A"),
        },
        {
            field: 'awbPcs',
            headerName: 'AWB Pcs',
            width: 110,
        },
        {
            field: 'pkgPcs',
            headerName: 'Pkg Pcs',
            width: 110,
        },
        {
            field: 'route',
            headerName: 'Route',
            width: 150,
        },
        {
            field: 'airport',
            headerName: 'Port',
            width: 110,
        },
        {
            field: 'statusincn',
            headerName: 'Status in CN',
            width: 120,
            renderCell: (params) => {

                return<>{params?.value==='00'?'未发送':'已发送'}</>
            }
        },
        {
            field: 'addtime',
            headerName: 'Create Time',
            width: 160,
            valueFormatter: params =>
                moment(params?.value).format("YYYY-MM-DD hh:mm A"),
        },
        { field: 'actions', headerName: 'Actions', width: 110, renderCell: (params) => {
                return (
                    <Button
                        onClick={()=>{

                            setEditData(params.row)
                            setOpenModal(true)
                            setIsEdit(true)
                        }}
                        variant="contained"
                        color="warning"
                    >
                        Edit
                    </Button>
                );
            }
        }

    ]



    const handleOpen = () => setOpenModal(true);
    const handleClose = () => {
        setOpenModal(false)
        setIsEdit(false)
        setEditData(null)
    }


    const style = {
        page: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            justifyContent: 'centre',
            padding: 2,


        },
        container: {
            width: '100%',
            alignItems: 'center',

        },
        searchContainer: {
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 2,
        }
    }

    const schema = yup.object().shape({})

    const {register, handleSubmit, formState: {errors}, setError, setValue} = useForm({
        resolver: yupResolver(schema),
        reValidateMode: 'onChange'
    })



    const renderSubmit = () =>
        <Grid container justifyContent='start' alignItems='center' spacing={2} sx={{width: '100%'}} mt={2}>
            <Grid item mr={3}>
                <Button color='warning'
                        variant='contained'
                        type='submit'>
                    Search
                </Button>
            </Grid>

            <Grid item mr={3}>
                <Button color='warning'
                        variant='contained'
                        onClick={()=>{
                            setEditData(null)
                            setIsEdit(false)
                            handleOpen()
                        }}
                >
                    add
                </Button>
            </Grid>

            <Grid item mr={3}>
                <Button color='warning'
                        variant='contained'
                        onClick={()=>setImportOpen(true)}>
                    Import
                </Button>
            </Grid>

        </Grid>

    const renderForm = () =>
        <Grid item container justifyContent='center' alignItems='center' spacing={2} sx={{width: '100%'}}>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <FormLabel component='legend'>Search Fields</FormLabel>
                </FormControl>
            </Grid>

            <Grid item container xs={12} spacing={2}>
                <Grid item xs={12} md={3} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                    <TextField
                        sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                        {...register('awbCode')}
                        variant='outlined'
                        label='AWB' name='awbCode' fullWidth
                        error={Boolean(errors.awb?.message)}
                        helperText={errors.awb?.message}
                        color="warning"
                    />
                </Grid>
                <Grid item xs={12} md={3}  sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                    <FormControl fullWidth color='warning' >
                        <Autocomplete
                            required
                            {...register('warehouse')}
                            disablePortal
                            options={allWarehouse}
                            getOptionLabel={(option) => `${option?.whId}. ${option?.whName}`}
                            isOptionEqualToValue={(option, value) => option?.whId === value?.whId}
                            value={selectWarehouse}
                            onChange={(e, v) => setSelectWarehouse(v)}
                            renderInput={(params) =>
                                <TextField
                                    {...register('warehouse')}
                                    color='warning' {...params}
                                    label="Warehouse" />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={3} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <LocalizationProvider dateAdapter={AdapterMoment}
                                          sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <DateTimePicker
                            sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                            views={['year', 'day', 'hours', 'minutes', 'seconds']}
                            label='ETA START FROM'
                            slotProps={{
                                textField: {color: 'warning', fullWidth: 'true'},
                                actionBar: {
                                    actions: ['clear']
                                },
                                popper: {
                                    sx:{
                                    "& .MuiDialogActions-root": {
                                        color: "red",
                                    }
                                    }
                                }
                            }}
                            onChange={( date) => setStartDate(date)}

                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={3} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <LocalizationProvider dateAdapter={AdapterMoment}
                                          sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <DateTimePicker
                            sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                            views={['year', 'day', 'hours', 'minutes']}
                            label='ETA END TO'
                            slotProps={{
                                textField: {color: 'warning', fullWidth: 'true'},
                                actionBar: {
                                    actions: ['clear']
                                }
                        }}

                            onChange={(date) => setEndDate(date)}
                        />
                    </LocalizationProvider>
                </Grid>

            </Grid>

        </Grid>


    function onSearchSuccess(data) {

        setTableLoading(false)
    }
    function PaperComponent(props) {
        return (
            <Draggable
                handle="#draggable-dialog-title"
                cancel={'[class*="MuiDialogContent-root"]'}
            >
                <Paper sx={{width:'50vw'}} {...props} />
            </Draggable>
        );
    }

    const onSubmit = (data) => {
        let searchData = {
        }
        if(data.awbCode!==''){
            searchData.awbCode = data.awbCode
        }
        if(data.warehouse!==''){
            searchData.whId = selectWarehouse.whId
        }

        if(startDate){
            searchData.beginTime = moment(startDate).format('YYYY-MM-DD HH:mm:ss')
        }

        if(endDate){
            searchData.endTime = moment(endDate).format('YYYY-MM-DD HH:mm:ss')
        }

        searchData.pageSize = pageSize
        searchData.pageNum = pageNum
        setSearch(searchData)
        const axiosPostAWBSearch = async () => {
            setTableLoading(true)
            await dispatch(searchAWB(searchData,onSearchSuccess,onError))
        }
        axiosPostAWBSearch().then()

    }
    function handlePaginationChange(data) {

        setPageNum(()=> data.page+1)
        setPageSize(()=>data.pageSize)
    }


    const handleUploadFile = async () => {

        if(uploadFile.length>0){
            const data = new FormData()

                data.append('file',uploadFile[0])
                const url = `${REACT_APP_API_URL}${awb_import_url}`
                const response = await axios.post(url, data, {
                    headers:{
                        'Content-Type': 'multipart/form-data',
                        'Access-Control-Allow-Origin': "*"
                    }
                })
                    .then((res) => res)
                    .catch(e => {
                        dispatch(onErrorAlert('Server Error'))
                    })
                if (response?.status === 200 && response.data.code === 200) {
                    setImportLoading(false)
                    dispatch(onSuccessAlert('Upload file successfully'))
                    localStorage.setItem('last_upload',JSON.stringify(moment().format()))

                } else {
                    dispatch(onErrorAlert(response?.data?.msg))
                    setImportLoading(false)
                }
        }

    }




    return (
        <Box m="20px"

             sx={style.page}>
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="AWB"/>
            </Box>
            <Box>
                <Divider variant="middle"/>
            </Box>
            {/* GRID & CHARTS */}
            <Grid
                container
                display="flex"
                flexDirection='column'
                sx={style.container}

            >
                <form onSubmit={handleSubmit(onSubmit)} style={{width: '100%'}}>
                    <Grid item container sx={style.searchContainer} p={2} backgroundColor={colors.primary[400]}>
                        {warehouseLoading?<CircularProgress/> :renderForm()}
                        {renderSubmit()}

                    </Grid>
                </form>

                <Grid item width='100%' mt={3} sx={{backgroundColor:colors.primary[400]}} p={1}>
                    <DataGrid
                        sx={{
                            border: 'none',
                            fontSize:'small',
                            fontWeight:'bold',
                            textAlign: 'center',
                            '& .MuiDataGrid-columnHeaderTitleContainer':{
                                backgroundColor:'warning'
                            }

                        }}

                        getRowId={(row)=> row.awbId}
                        rows={tablerows}
                        columns={columns}
                        pageSizeOptions={[10,25,50]}
                        initialState={{
                            pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        onPaginationModelChange = {
                            handlePaginationChange
                        }
                        loading={stableLoading}
                    />
                </Grid>
                <AwbEditor search={search} openModal={openModal} setOpenModal={setOpenModal} data={editData} isEdit={isEdit} handleClose={handleClose}/>
                <Modal
                    open={importOpen}
                    onClose={()=>setImportOpen(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Grid  item container alignItems='center' justifyContent='center' flexDirection={'column'} xs={12} sx={modalStyle} >
                        <FileUploader
                            asFormInputComponent={true}
                            handleImageCallBack ={file=> setUploadFile(file)}
                            list={[]}
                            onError={(e)=>{
                                console.log(e)}}
                            onSuccess={()=>{
                                console.log('success')
                            }}
                            multipleFile={false}
                        />
                        <Grid sx={{display:'flex'}} xs={6} spacing={2}>
                            <Button  onClick={handleUploadFile}  variant="outlined" sx={{color: '#ed6c02'}}>submit</Button>
                            <Button variant="outlined" sx={{color: '#ed6c02'}} onClick={()=>setImportOpen(false)}>close</Button>
                        </Grid>


                   </Grid>
                </Modal>

            </Grid>
        </Box>
    );
};

export default AWB;