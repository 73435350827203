import {Box, Button, IconButton, useTheme} from "@mui/material";
import {  tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import {useDispatch, useSelector} from "react-redux";
import {logout, onSuccessAlert, toggleMode} from "../../actions";
import {useEffect} from "react";
import {useNavigate} from "react-router";

const TopBar = () => {
    const mode = useSelector((state) => state.theme.mode);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleChangeMode = () => {
        dispatch(toggleMode())
    }

    function onSuccess() {
        dispatch(onSuccessAlert('Logout Successfully'))
        navigate('/')
    }

    function handleLogout() {
        dispatch(logout(onSuccess))
    }

    return (
        <Box display="flex" justifyContent="space-between" p={2}>
            {/* SEARCH BAR */}
            <Box
                display="flex"
                backgroundColor={colors.primary[400]}
                borderRadius="3px"
            >
                <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
                <IconButton type="button" sx={{ p: 1 }}>
                    <SearchIcon />
                </IconButton>
            </Box>

            {/* ICONS */}
            <Box display="flex">
                <IconButton onClick={handleChangeMode}>
                    {theme.palette.mode === "dark" ? (
                        <DarkModeOutlinedIcon />
                    ) : (
                        <LightModeOutlinedIcon />
                    )}
                </IconButton>
                <IconButton>
                    <NotificationsOutlinedIcon />
                </IconButton>
                <IconButton>
                    <SettingsOutlinedIcon />
                </IconButton>
                <IconButton>
                    <PersonOutlinedIcon />
                </IconButton>
                <Button color='warning' onClick={handleLogout}>Log out</Button>
            </Box>
        </Box>
    );
};

export default TopBar;